import { OTCListing } from "../types";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useActiveWeb3React } from "./useActiveWeb3React";
import { useWriteContract } from "wagmi";
import useNotification from "../utilities/notificationUtils";
import {
  chainIdToEndpoint,
  chainToConfig,
  otcAddress,
  swapContractConfig,
} from "../config";
import { Options } from "@layerzerolabs/lz-v2-utilities";
import { readContract } from "@wagmi/core";
import { AbiCoder, ZeroHash } from "ethers";

const txTypes = {
  SEND: 0,
  SEND_ACK: 1,
  SEND_FAIL: 2,
};

const abi = new AbiCoder();

export function useManageListing(
  listing: OTCListing,
  setLoading: Dispatch<SetStateAction<boolean>>,
  onClose: any,
  refetchListings: any,
  collectionOfferNFTID?: number
) {
  const { account, chainId, publicClient } = useActiveWeb3React();
  const { writeContractAsync } = useWriteContract();
  const { showSuccess, showError } = useNotification();

  const claimListing = useCallback(async () => {
    setLoading(true);
    try {
      const claimListingParams = {
        _listingId: listing.listingId,
        destinationEndpoint: chainIdToEndpoint[listing.srcChain],
        tokenForSale: listing.tokenForSale,
        tokenToReceive: collectionOfferNFTID
          ? { ...listing.tokenToReceive, value: Number(collectionOfferNFTID) }
          : listing.tokenToReceive,
      };
      const isSameChain =
        claimListingParams.destinationEndpoint === chainIdToEndpoint[chainId];
      const options = Options.newOptions().addExecutorLzReceiveOption(
        1000000,
        0
      );
      const destChainId = listing.srcChain;
      const { nativeFee }: any = isSameChain
        ? { nativeFee: 0 }
        : await readContract(chainToConfig[destChainId], {
            functionName: "quote",
            address: otcAddress[destChainId] as any,
            abi: swapContractConfig.abi,
            args: [
              chainIdToEndpoint[chainId],
              txTypes.SEND_ACK,
              options.toHex(),
              abi.encode(
                ["uint256", "address", "address"],
                [0, account, account]
              ),
            ],
          });
      const encodedPayload = isSameChain
        ? "0"
        : await readContract(chainToConfig[chainId], {
            functionName: "getSendParamsEncoded",
            address: otcAddress[chainId] as any,
            abi: swapContractConfig.abi,
            args: [claimListingParams, account, options.toHex()],
          });
      const srcOptions = Options.newOptions().addExecutorLzReceiveOption(
        1000000,
        nativeFee.toString()
      );
      const srcFee: any = isSameChain
        ? { nativeFee: 0 }
        : await readContract(chainToConfig[chainId], {
            functionName: "quote",
            address: otcAddress[chainId] as any,
            abi: swapContractConfig.abi,
            args: [
              chainIdToEndpoint[destChainId],
              txTypes.SEND,
              srcOptions.toHex(),
              encodedPayload,
            ],
          });
      const tx = await writeContractAsync({
        abi: swapContractConfig.abi,
        args: [
          srcFee.nativeFee.toString(),
          claimListingParams,
          isSameChain ? ZeroHash : srcOptions.toHex(),
          isSameChain ? ZeroHash : options.toHex(),
        ],
        address: otcAddress[chainId] as any,
        functionName: "claimListing",
        account: account,
        value: isSameChain ? 0 : srcFee.nativeFee + nativeFee + BigInt(2000000),
      });

      await publicClient?.waitForTransactionReceipt({ hash: tx });
      showSuccess(
        "Successfully claimed listing, the amount should reflect in your wallet shortly"
      );
      onClose();
      refetchListings();
    } catch (error) {
      console.error(error);
      showError("An error occurred while claiming listing");
    } finally {
      setLoading(false);
    }
  }, [
    listing,
    writeContractAsync,
    setLoading,
    publicClient,
    showSuccess,
    showError,
    collectionOfferNFTID,
    account,
    chainId,
    refetchListings,
    onClose,
  ]);

  const cancelListing = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await writeContractAsync({
        abi: swapContractConfig.abi,
        args: [listing.listingId],
        address: otcAddress[chainId] as any,
        functionName: "closeListing",
        account: account,
      });

      await publicClient?.waitForTransactionReceipt({ hash: tx });
      showSuccess("Successfully cancelled listing");
      refetchListings();
      onClose();
    } catch (error) {
      showError("An error occurred while cancelling your listing");
    } finally {
      setLoading(false);
    }
  }, [
    listing,
    writeContractAsync,
    setLoading,
    publicClient,
    showSuccess,
    showError,
    account,
    chainId,
    refetchListings,
    onClose,
  ]);

  return { claimListing, cancelListing };
}
