const swapContractConfig = {
  // address: "0x644305ECDB24552fe83Dcd0548AcE880E65baa97", // testing
  // address: "0xD82B4D63982E0a0a65Eb3A5840a75500f130Ed3C", // production v1
  // address: "0xc5Bf05445369A7a88A2B2e5d0Bf629dDE5ED56c4", // production v2
  abi: [
    {
      inputs: [
        {
          internalType: "address",
          name: "_endpoint",
          type: "address",
        },
        {
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          internalType: "address[]",
          name: "_handlers",
          type: "address[]",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [],
      name: "InvalidDelegate",
      type: "error",
    },
    {
      inputs: [],
      name: "InvalidEndpointCall",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "bytes",
          name: "options",
          type: "bytes",
        },
      ],
      name: "InvalidOptions",
      type: "error",
    },
    {
      inputs: [],
      name: "LzTokenUnavailable",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "eid",
          type: "uint32",
        },
      ],
      name: "NoPeer",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "msgValue",
          type: "uint256",
        },
      ],
      name: "NotEnoughNative",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_AddressZeroInvalid",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_ClaimInvalid",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_EndpointInvalid",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_HandlerInvalid",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_InvalidEndpoint",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_InvalidTokenAmount",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_NoListing",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_NotSeller",
      type: "error",
    },
    {
      inputs: [],
      name: "OTC_TokenAmountZero",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "addr",
          type: "address",
        },
      ],
      name: "OnlyEndpoint",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "eid",
          type: "uint32",
        },
        {
          internalType: "bytes32",
          name: "sender",
          type: "bytes32",
        },
      ],
      name: "OnlyPeer",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "OwnableInvalidOwner",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "OwnableUnauthorizedAccount",
      type: "error",
    },
    {
      inputs: [],
      name: "ReentrancyGuardReentrantCall",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "token",
          type: "address",
        },
      ],
      name: "SafeERC20FailedOperation",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "eid",
              type: "uint32",
            },
            {
              internalType: "uint16",
              name: "msgType",
              type: "uint16",
            },
            {
              internalType: "bytes",
              name: "options",
              type: "bytes",
            },
          ],
          indexed: false,
          internalType: "struct EnforcedOptionParam[]",
          name: "_enforcedOptions",
          type: "tuple[]",
        },
      ],
      name: "EnforcedOptionSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "listingId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "buyer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "feeForSale",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "feeToReceive",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "isCrossChain",
          type: "bool",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "referenceListingId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "mirrored",
          type: "bool",
        },
      ],
      name: "ListingClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "listingId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "seller",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amountForSaleReturned",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "isCrossChain",
          type: "bool",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "mirrored",
          type: "bool",
        },
      ],
      name: "ListingClosed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "listingId",
          type: "uint256",
        },
        {
          components: [
            {
              internalType: "uint32",
              name: "destinationEndpoint",
              type: "uint32",
            },
            {
              internalType: "address",
              name: "seller",
              type: "address",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenForSale",
              type: "tuple",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenToReceive",
              type: "tuple",
            },
          ],
          indexed: false,
          internalType: "struct IListing.Listing",
          name: "listing",
          type: "tuple",
        },
      ],
      name: "ListingCreated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "listingId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "ListingProcessedOnDestination",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint32",
          name: "eid",
          type: "uint32",
        },
        {
          indexed: false,
          internalType: "bytes32",
          name: "peer",
          type: "bytes32",
        },
      ],
      name: "PeerSet",
      type: "event",
    },
    {
      inputs: [],
      name: "SEND",
      outputs: [
        {
          internalType: "uint16",
          name: "",
          type: "uint16",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "SEND_ACK",
      outputs: [
        {
          internalType: "uint16",
          name: "",
          type: "uint16",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "SEND_FAIL",
      outputs: [
        {
          internalType: "uint16",
          name: "",
          type: "uint16",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_handler",
          type: "address",
        },
      ],
      name: "addHandler",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "srcEid",
              type: "uint32",
            },
            {
              internalType: "bytes32",
              name: "sender",
              type: "bytes32",
            },
            {
              internalType: "uint64",
              name: "nonce",
              type: "uint64",
            },
          ],
          internalType: "struct Origin",
          name: "origin",
          type: "tuple",
        },
      ],
      name: "allowInitializePath",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint128",
          name: "_srcFee",
          type: "uint128",
        },
        {
          components: [
            {
              internalType: "uint256",
              name: "_listingId",
              type: "uint256",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenForSale",
              type: "tuple",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenToReceive",
              type: "tuple",
            },
            {
              internalType: "uint32",
              name: "destinationEndpoint",
              type: "uint32",
            },
          ],
          internalType: "struct IListing.ClaimListingParams",
          name: "_listingParams",
          type: "tuple",
        },
        {
          internalType: "bytes",
          name: "_options",
          type: "bytes",
        },
        {
          internalType: "bytes",
          name: "_receiverOptions",
          type: "bytes",
        },
      ],
      name: "claimListing",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_listingId",
          type: "uint256",
        },
      ],
      name: "closeListing",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "_eid",
          type: "uint32",
        },
        {
          internalType: "uint16",
          name: "_msgType",
          type: "uint16",
        },
        {
          internalType: "bytes",
          name: "_extraOptions",
          type: "bytes",
        },
      ],
      name: "combineOptions",
      outputs: [
        {
          internalType: "bytes",
          name: "",
          type: "bytes",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "destinationEndpoint",
              type: "uint32",
            },
            {
              internalType: "address",
              name: "seller",
              type: "address",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenForSale",
              type: "tuple",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenToReceive",
              type: "tuple",
            },
          ],
          internalType: "struct IListing.Listing",
          name: "_listing",
          type: "tuple",
        },
      ],
      name: "createListing",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "endpoint",
      outputs: [
        {
          internalType: "contract ILayerZeroEndpointV2",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "eid",
          type: "uint32",
        },
        {
          internalType: "uint16",
          name: "msgType",
          type: "uint16",
        },
      ],
      name: "enforcedOptions",
      outputs: [
        {
          internalType: "bytes",
          name: "enforcedOption",
          type: "bytes",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "startIndex",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "max",
          type: "uint256",
        },
      ],
      name: "getAllUnclaimedListings",
      outputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "destinationEndpoint",
              type: "uint32",
            },
            {
              internalType: "address",
              name: "seller",
              type: "address",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenForSale",
              type: "tuple",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenToReceive",
              type: "tuple",
            },
          ],
          internalType: "struct IListing.Listing[]",
          name: "",
          type: "tuple[]",
        },
        {
          internalType: "uint256[]",
          name: "",
          type: "uint256[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint256",
              name: "_listingId",
              type: "uint256",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenForSale",
              type: "tuple",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "contractAddress",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "handler",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "value",
                  type: "uint256",
                },
              ],
              internalType: "struct IListing.Token",
              name: "tokenToReceive",
              type: "tuple",
            },
            {
              internalType: "uint32",
              name: "destinationEndpoint",
              type: "uint32",
            },
          ],
          internalType: "struct IListing.ClaimListingParams",
          name: "_claimListingParams",
          type: "tuple",
        },
        {
          internalType: "address",
          name: "_account",
          type: "address",
        },
        {
          internalType: "bytes",
          name: "_receiveOptions",
          type: "bytes",
        },
      ],
      name: "getSendParamsEncoded",
      outputs: [
        {
          internalType: "bytes",
          name: "",
          type: "bytes",
        },
      ],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "handlers",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "srcEid",
              type: "uint32",
            },
            {
              internalType: "bytes32",
              name: "sender",
              type: "bytes32",
            },
            {
              internalType: "uint64",
              name: "nonce",
              type: "uint64",
            },
          ],
          internalType: "struct Origin",
          name: "",
          type: "tuple",
        },
        {
          internalType: "bytes",
          name: "",
          type: "bytes",
        },
        {
          internalType: "address",
          name: "_sender",
          type: "address",
        },
      ],
      name: "isComposeMsgSender",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      name: "listings",
      outputs: [
        {
          internalType: "uint32",
          name: "destinationEndpoint",
          type: "uint32",
        },
        {
          internalType: "address",
          name: "seller",
          type: "address",
        },
        {
          components: [
            {
              internalType: "address",
              name: "contractAddress",
              type: "address",
            },
            {
              internalType: "address",
              name: "handler",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          internalType: "struct IListing.Token",
          name: "tokenForSale",
          type: "tuple",
        },
        {
          components: [
            {
              internalType: "address",
              name: "contractAddress",
              type: "address",
            },
            {
              internalType: "address",
              name: "handler",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          internalType: "struct IListing.Token",
          name: "tokenToReceive",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "srcEid",
              type: "uint32",
            },
            {
              internalType: "bytes32",
              name: "sender",
              type: "bytes32",
            },
            {
              internalType: "uint64",
              name: "nonce",
              type: "uint64",
            },
          ],
          internalType: "struct Origin",
          name: "_origin",
          type: "tuple",
        },
        {
          internalType: "bytes32",
          name: "_guid",
          type: "bytes32",
        },
        {
          internalType: "bytes",
          name: "_message",
          type: "bytes",
        },
        {
          internalType: "address",
          name: "_executor",
          type: "address",
        },
        {
          internalType: "bytes",
          name: "_extraData",
          type: "bytes",
        },
      ],
      name: "lzReceive",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "nextListingId",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "",
          type: "uint32",
        },
        {
          internalType: "bytes32",
          name: "",
          type: "bytes32",
        },
      ],
      name: "nextNonce",
      outputs: [
        {
          internalType: "uint64",
          name: "nonce",
          type: "uint64",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "oAppVersion",
      outputs: [
        {
          internalType: "uint64",
          name: "senderVersion",
          type: "uint64",
        },
        {
          internalType: "uint64",
          name: "receiverVersion",
          type: "uint64",
        },
      ],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "eid",
          type: "uint32",
        },
      ],
      name: "peers",
      outputs: [
        {
          internalType: "bytes32",
          name: "peer",
          type: "bytes32",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      name: "pendingBuys",
      outputs: [
        {
          internalType: "uint256",
          name: "_listingId",
          type: "uint256",
        },
        {
          components: [
            {
              internalType: "address",
              name: "contractAddress",
              type: "address",
            },
            {
              internalType: "address",
              name: "handler",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          internalType: "struct IListing.Token",
          name: "tokenForSale",
          type: "tuple",
        },
        {
          components: [
            {
              internalType: "address",
              name: "contractAddress",
              type: "address",
            },
            {
              internalType: "address",
              name: "handler",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          internalType: "struct IListing.Token",
          name: "tokenToReceive",
          type: "tuple",
        },
        {
          internalType: "uint32",
          name: "destinationEndpoint",
          type: "uint32",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "_dstEid",
          type: "uint32",
        },
        {
          internalType: "uint16",
          name: "_txType",
          type: "uint16",
        },
        {
          internalType: "bytes",
          name: "_options",
          type: "bytes",
        },
        {
          internalType: "bytes",
          name: "_payloadData",
          type: "bytes",
        },
      ],
      name: "quote",
      outputs: [
        {
          components: [
            {
              internalType: "uint256",
              name: "nativeFee",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "lzTokenFee",
              type: "uint256",
            },
          ],
          internalType: "struct MessagingFee",
          name: "fee",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_handler",
          type: "address",
        },
      ],
      name: "removeHandler",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_delegate",
          type: "address",
        },
      ],
      name: "setDelegate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "uint32",
              name: "eid",
              type: "uint32",
            },
            {
              internalType: "uint16",
              name: "msgType",
              type: "uint16",
            },
            {
              internalType: "bytes",
              name: "options",
              type: "bytes",
            },
          ],
          internalType: "struct EnforcedOptionParam[]",
          name: "_enforcedOptions",
          type: "tuple[]",
        },
      ],
      name: "setEnforcedOptions",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint32",
          name: "_eid",
          type: "uint32",
        },
        {
          internalType: "bytes32",
          name: "_peer",
          type: "bytes32",
        },
      ],
      name: "setPeer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      stateMutability: "payable",
      type: "receive",
    },
  ],
};

const tokenContractConfig = {
  dmtForSankoContractAddress: "0x0e91f84c1cb7abef54734b6ac4f132cc653dc4e2",
  dmtForArbitumContractAddress: "0xfa9918467b587891aaf17563a03a23d098be1c05",
  abi: [
    {
      inputs: [
        {
          internalType: "uint256",
          name: "initialSupply",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "allowance",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "needed",
          type: "uint256",
        },
      ],
      name: "ERC20InsufficientAllowance",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "balance",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "needed",
          type: "uint256",
        },
      ],
      name: "ERC20InsufficientBalance",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "approver",
          type: "address",
        },
      ],
      name: "ERC20InvalidApprover",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "ERC20InvalidReceiver",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "sender",
          type: "address",
        },
      ],
      name: "ERC20InvalidSender",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
      ],
      name: "ERC20InvalidSpender",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
      ],
      name: "allowance",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [
        {
          internalType: "uint8",
          name: "",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
};

const nftContractConfig = {
  dmtForSankoContractAddress: "0x0e91f84c1cb7abef54734b6ac4f132cc653dc4e2",
  dmtForArbitumContractAddress: "0xfa9918467b587891aaf17563a03a23d098be1c05",
  abi: [
    {
      inputs: [
        {
          internalType: "address[]",
          name: "owners",
          type: "address[]",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "ERC721IncorrectOwner",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ERC721InsufficientApproval",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "approver",
          type: "address",
        },
      ],
      name: "ERC721InvalidApprover",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "ERC721InvalidOperator",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "ERC721InvalidOwner",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "ERC721InvalidReceiver",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "sender",
          type: "address",
        },
      ],
      name: "ERC721InvalidSender",
      type: "error",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ERC721NonexistentToken",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "getApproved",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "isApprovedForAll",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ownerOf",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "bytes",
          name: "data",
          type: "bytes",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes4",
          name: "interfaceId",
          type: "bytes4",
        },
      ],
      name: "supportsInterface",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "tokenURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
};

const uniswapv3 = {
  abi: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickLower",
          type: "int24",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickUpper",
          type: "int24",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount",
          type: "uint128",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1",
          type: "uint256",
        },
      ],
      name: "Burn",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickLower",
          type: "int24",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickUpper",
          type: "int24",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount0",
          type: "uint128",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount1",
          type: "uint128",
        },
      ],
      name: "Collect",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount0",
          type: "uint128",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount1",
          type: "uint128",
        },
      ],
      name: "CollectProtocol",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "paid0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "paid1",
          type: "uint256",
        },
      ],
      name: "Flash",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint16",
          name: "observationCardinalityNextOld",
          type: "uint16",
        },
        {
          indexed: false,
          internalType: "uint16",
          name: "observationCardinalityNextNew",
          type: "uint16",
        },
      ],
      name: "IncreaseObservationCardinalityNext",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint160",
          name: "sqrtPriceX96",
          type: "uint160",
        },
        {
          indexed: false,
          internalType: "int24",
          name: "tick",
          type: "int24",
        },
      ],
      name: "Initialize",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickLower",
          type: "int24",
        },
        {
          indexed: true,
          internalType: "int24",
          name: "tickUpper",
          type: "int24",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "amount",
          type: "uint128",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1",
          type: "uint256",
        },
      ],
      name: "Mint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint8",
          name: "feeProtocol0Old",
          type: "uint8",
        },
        {
          indexed: false,
          internalType: "uint8",
          name: "feeProtocol1Old",
          type: "uint8",
        },
        {
          indexed: false,
          internalType: "uint8",
          name: "feeProtocol0New",
          type: "uint8",
        },
        {
          indexed: false,
          internalType: "uint8",
          name: "feeProtocol1New",
          type: "uint8",
        },
      ],
      name: "SetFeeProtocol",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "int256",
          name: "amount0",
          type: "int256",
        },
        {
          indexed: false,
          internalType: "int256",
          name: "amount1",
          type: "int256",
        },
        {
          indexed: false,
          internalType: "uint160",
          name: "sqrtPriceX96",
          type: "uint160",
        },
        {
          indexed: false,
          internalType: "uint128",
          name: "liquidity",
          type: "uint128",
        },
        {
          indexed: false,
          internalType: "int24",
          name: "tick",
          type: "int24",
        },
      ],
      name: "Swap",
      type: "event",
    },
    {
      inputs: [
        { internalType: "int24", name: "tickLower", type: "int24" },
        { internalType: "int24", name: "tickUpper", type: "int24" },
        { internalType: "uint128", name: "amount", type: "uint128" },
      ],
      name: "burn",
      outputs: [
        { internalType: "uint256", name: "amount0", type: "uint256" },
        { internalType: "uint256", name: "amount1", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "int24", name: "tickLower", type: "int24" },
        { internalType: "int24", name: "tickUpper", type: "int24" },
        {
          internalType: "uint128",
          name: "amount0Requested",
          type: "uint128",
        },
        {
          internalType: "uint128",
          name: "amount1Requested",
          type: "uint128",
        },
      ],
      name: "collect",
      outputs: [
        { internalType: "uint128", name: "amount0", type: "uint128" },
        { internalType: "uint128", name: "amount1", type: "uint128" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        {
          internalType: "uint128",
          name: "amount0Requested",
          type: "uint128",
        },
        {
          internalType: "uint128",
          name: "amount1Requested",
          type: "uint128",
        },
      ],
      name: "collectProtocol",
      outputs: [
        { internalType: "uint128", name: "amount0", type: "uint128" },
        { internalType: "uint128", name: "amount1", type: "uint128" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "factory",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "fee",
      outputs: [{ internalType: "uint24", name: "", type: "uint24" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "feeGrowthGlobal0X128",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "feeGrowthGlobal1X128",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount0", type: "uint256" },
        { internalType: "uint256", name: "amount1", type: "uint256" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "flash",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint16",
          name: "observationCardinalityNext",
          type: "uint16",
        },
      ],
      name: "increaseObservationCardinalityNext",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint160", name: "sqrtPriceX96", type: "uint160" },
      ],
      name: "initialize",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "liquidity",
      outputs: [{ internalType: "uint128", name: "", type: "uint128" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxLiquidityPerTick",
      outputs: [{ internalType: "uint128", name: "", type: "uint128" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "int24", name: "tickLower", type: "int24" },
        { internalType: "int24", name: "tickUpper", type: "int24" },
        { internalType: "uint128", name: "amount", type: "uint128" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "mint",
      outputs: [
        { internalType: "uint256", name: "amount0", type: "uint256" },
        { internalType: "uint256", name: "amount1", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "observations",
      outputs: [
        { internalType: "uint32", name: "blockTimestamp", type: "uint32" },
        { internalType: "int56", name: "tickCumulative", type: "int56" },
        {
          internalType: "uint160",
          name: "secondsPerLiquidityCumulativeX128",
          type: "uint160",
        },
        { internalType: "bool", name: "initialized", type: "bool" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint32[]", name: "secondsAgos", type: "uint32[]" },
      ],
      name: "observe",
      outputs: [
        {
          internalType: "int56[]",
          name: "tickCumulatives",
          type: "int56[]",
        },
        {
          internalType: "uint160[]",
          name: "secondsPerLiquidityCumulativeX128s",
          type: "uint160[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      name: "positions",
      outputs: [
        { internalType: "uint128", name: "liquidity", type: "uint128" },
        {
          internalType: "uint256",
          name: "feeGrowthInside0LastX128",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "feeGrowthInside1LastX128",
          type: "uint256",
        },
        { internalType: "uint128", name: "tokensOwed0", type: "uint128" },
        { internalType: "uint128", name: "tokensOwed1", type: "uint128" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "protocolFees",
      outputs: [
        { internalType: "uint128", name: "token0", type: "uint128" },
        { internalType: "uint128", name: "token1", type: "uint128" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint8", name: "feeProtocol0", type: "uint8" },
        { internalType: "uint8", name: "feeProtocol1", type: "uint8" },
      ],
      name: "setFeeProtocol",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "slot0",
      outputs: [
        { internalType: "uint160", name: "sqrtPriceX96", type: "uint160" },
        { internalType: "int24", name: "tick", type: "int24" },
        {
          internalType: "uint16",
          name: "observationIndex",
          type: "uint16",
        },
        {
          internalType: "uint16",
          name: "observationCardinality",
          type: "uint16",
        },
        {
          internalType: "uint16",
          name: "observationCardinalityNext",
          type: "uint16",
        },
        { internalType: "uint8", name: "feeProtocol", type: "uint8" },
        { internalType: "bool", name: "unlocked", type: "bool" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "int24", name: "tickLower", type: "int24" },
        { internalType: "int24", name: "tickUpper", type: "int24" },
      ],
      name: "snapshotCumulativesInside",
      outputs: [
        {
          internalType: "int56",
          name: "tickCumulativeInside",
          type: "int56",
        },
        {
          internalType: "uint160",
          name: "secondsPerLiquidityInsideX128",
          type: "uint160",
        },
        { internalType: "uint32", name: "secondsInside", type: "uint32" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "bool", name: "zeroForOne", type: "bool" },
        { internalType: "int256", name: "amountSpecified", type: "int256" },
        {
          internalType: "uint160",
          name: "sqrtPriceLimitX96",
          type: "uint160",
        },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "swap",
      outputs: [
        { internalType: "int256", name: "amount0", type: "int256" },
        { internalType: "int256", name: "amount1", type: "int256" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "int16", name: "", type: "int16" }],
      name: "tickBitmap",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "tickSpacing",
      outputs: [{ internalType: "int24", name: "", type: "int24" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "int24", name: "", type: "int24" }],
      name: "ticks",
      outputs: [
        {
          internalType: "uint128",
          name: "liquidityGross",
          type: "uint128",
        },
        { internalType: "int128", name: "liquidityNet", type: "int128" },
        {
          internalType: "uint256",
          name: "feeGrowthOutside0X128",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "feeGrowthOutside1X128",
          type: "uint256",
        },
        {
          internalType: "int56",
          name: "tickCumulativeOutside",
          type: "int56",
        },
        {
          internalType: "uint160",
          name: "secondsPerLiquidityOutsideX128",
          type: "uint160",
        },
        { internalType: "uint32", name: "secondsOutside", type: "uint32" },
        { internalType: "bool", name: "initialized", type: "bool" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "token0",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "token1",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
  ],
};

const nftUtils = {
  abi: [
    {
      inputs: [
        {
          internalType: "address",
          name: "nft",
          type: "address",
        },
        {
          internalType: "address",
          name: "holder",
          type: "address",
        },
      ],
      name: "viewBalance",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "nft",
          type: "address",
        },
        {
          internalType: "address",
          name: "holder",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "supply",
          type: "uint256",
        },
      ],
      name: "viewOwnedIds",
      outputs: [
        {
          internalType: "uint256[]",
          name: "returnData",
          type: "uint256[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "nft",
          type: "address",
        },
        {
          internalType: "address",
          name: "holder",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "startTokenId",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "endTokenId",
          type: "uint256",
        },
      ],
      name: "viewOwnedIdsBatch",
      outputs: [
        {
          internalType: "uint256[]",
          name: "returnData",
          type: "uint256[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ],
};

const dopexV2OptionMarket = {
  abi: [
    {
      type: "constructor",
      inputs: [
        { name: "_pm", type: "address", internalType: "address" },
        {
          name: "_optionPricing",
          type: "address",
          internalType: "address",
        },
        { name: "_dpFee", type: "address", internalType: "address" },
        { name: "_callAsset", type: "address", internalType: "address" },
        { name: "_putAsset", type: "address", internalType: "address" },
        { name: "_primePool", type: "address", internalType: "address" },
      ],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "approve",
      inputs: [
        { name: "account", type: "address", internalType: "address" },
        { name: "id", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
    },
    {
      type: "function",
      name: "approvedPools",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "balanceOf",
      inputs: [{ name: "owner", type: "address", internalType: "address" }],
      outputs: [{ name: "result", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "callAsset",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "callAssetDecimals",
      inputs: [],
      outputs: [{ name: "", type: "uint8", internalType: "uint8" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "dpFee",
      inputs: [],
      outputs: [
        {
          name: "",
          type: "address",
          internalType: "contract IDopexV2ClammFeeStrategyV2",
        },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "emergencyWithdraw",
      inputs: [{ name: "token", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "exerciseDelegator",
      inputs: [
        { name: "", type: "address", internalType: "address" },
        { name: "", type: "address", internalType: "address" },
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "exerciseOption",
      inputs: [
        {
          name: "_params",
          type: "tuple",
          internalType: "struct DopexV2OptionMarketV2.ExerciseOptionParams",
          components: [
            {
              name: "optionId",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "swapper",
              type: "address[]",
              internalType: "contract ISwapper[]",
            },
            {
              name: "swapData",
              type: "bytes[]",
              internalType: "bytes[]",
            },
            {
              name: "liquidityToExercise",
              type: "uint256[]",
              internalType: "uint256[]",
            },
          ],
        },
      ],
      outputs: [
        {
          name: "ac",
          type: "tuple",
          internalType: "struct DopexV2OptionMarketV2.AssetsCache",
          components: [
            {
              name: "assetToUse",
              type: "address",
              internalType: "contract ERC20",
            },
            {
              name: "assetToGet",
              type: "address",
              internalType: "contract ERC20",
            },
            {
              name: "totalProfit",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "totalAssetRelocked",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
      ],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "feeTo",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "getApproved",
      inputs: [{ name: "id", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "result", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "getCurrentPricePerCallAsset",
      inputs: [
        {
          name: "_pool",
          type: "address",
          internalType: "contract IUniswapV3Pool",
        },
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "getFee",
      inputs: [
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "premium", type: "uint256", internalType: "uint256" },
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "getPremiumAmount",
      inputs: [
        { name: "isPut", type: "bool", internalType: "bool" },
        { name: "expiry", type: "uint256", internalType: "uint256" },
        { name: "strike", type: "uint256", internalType: "uint256" },
        { name: "lastPrice", type: "uint256", internalType: "uint256" },
        { name: "amount", type: "uint256", internalType: "uint256" },
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "getPricePerCallAssetViaTick",
      inputs: [
        {
          name: "_pool",
          type: "address",
          internalType: "contract IUniswapV3Pool",
        },
        { name: "_tick", type: "int24", internalType: "int24" },
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "isApprovedForAll",
      inputs: [
        { name: "owner", type: "address", internalType: "address" },
        { name: "operator", type: "address", internalType: "address" },
      ],
      outputs: [{ name: "result", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "mintOption",
      inputs: [
        {
          name: "_params",
          type: "tuple",
          internalType: "struct DopexV2OptionMarketV2.OptionParams",
          components: [
            {
              name: "optionTicks",
              type: "tuple[]",
              internalType: "struct DopexV2OptionMarketV2.OptionTicks[]",
              components: [
                {
                  name: "_handler",
                  type: "address",
                  internalType: "contract IHandler",
                },
                {
                  name: "pool",
                  type: "address",
                  internalType: "contract IUniswapV3Pool",
                },
                {
                  name: "hook",
                  type: "address",
                  internalType: "address",
                },
                {
                  name: "tickLower",
                  type: "int24",
                  internalType: "int24",
                },
                {
                  name: "tickUpper",
                  type: "int24",
                  internalType: "int24",
                },
                {
                  name: "liquidityToUse",
                  type: "uint256",
                  internalType: "uint256",
                },
              ],
            },
            { name: "tickLower", type: "int24", internalType: "int24" },
            { name: "tickUpper", type: "int24", internalType: "int24" },
            { name: "ttl", type: "uint256", internalType: "uint256" },
            { name: "isCall", type: "bool", internalType: "bool" },
            {
              name: "maxCostAllowance",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "multicall",
      inputs: [{ name: "data", type: "bytes[]", internalType: "bytes[]" }],
      outputs: [{ name: "results", type: "bytes[]", internalType: "bytes[]" }],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "name",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "opData",
      inputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      outputs: [
        {
          name: "opTickArrayLen",
          type: "uint256",
          internalType: "uint256",
        },
        { name: "tickLower", type: "int24", internalType: "int24" },
        { name: "tickUpper", type: "int24", internalType: "int24" },
        { name: "expiry", type: "uint256", internalType: "uint256" },
        { name: "isCall", type: "bool", internalType: "bool" },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "opTickMap",
      inputs: [
        { name: "", type: "uint256", internalType: "uint256" },
        { name: "", type: "uint256", internalType: "uint256" },
      ],
      outputs: [
        {
          name: "_handler",
          type: "address",
          internalType: "contract IHandler",
        },
        {
          name: "pool",
          type: "address",
          internalType: "contract IUniswapV3Pool",
        },
        { name: "hook", type: "address", internalType: "address" },
        { name: "tickLower", type: "int24", internalType: "int24" },
        { name: "tickUpper", type: "int24", internalType: "int24" },
        {
          name: "liquidityToUse",
          type: "uint256",
          internalType: "uint256",
        },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "optionIds",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "optionPricing",
      inputs: [],
      outputs: [
        {
          name: "",
          type: "address",
          internalType: "contract IOptionPricingV2",
        },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "owner",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "ownerOf",
      inputs: [{ name: "id", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "result", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "positionManager",
      inputs: [],
      outputs: [
        {
          name: "",
          type: "address",
          internalType: "contract IDopexV2PositionManager",
        },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "positionSplitter",
      inputs: [
        {
          name: "_params",
          type: "tuple",
          internalType: "struct DopexV2OptionMarketV2.PositionSplitterParams",
          components: [
            {
              name: "optionId",
              type: "uint256",
              internalType: "uint256",
            },
            { name: "to", type: "address", internalType: "address" },
            {
              name: "liquidityToSplit",
              type: "uint256[]",
              internalType: "uint256[]",
            },
          ],
        },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "primePool",
      inputs: [],
      outputs: [
        {
          name: "",
          type: "address",
          internalType: "contract IUniswapV3Pool",
        },
      ],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "putAsset",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "putAssetDecimals",
      inputs: [],
      outputs: [{ name: "", type: "uint8", internalType: "uint8" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "renounceOwnership",
      inputs: [],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "safeTransferFrom",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "to", type: "address", internalType: "address" },
        { name: "id", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
    },
    {
      type: "function",
      name: "safeTransferFrom",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "to", type: "address", internalType: "address" },
        { name: "id", type: "uint256", internalType: "uint256" },
        { name: "data", type: "bytes", internalType: "bytes" },
      ],
      outputs: [],
      stateMutability: "payable",
    },
    {
      type: "function",
      name: "setApprovalForAll",
      inputs: [
        { name: "operator", type: "address", internalType: "address" },
        { name: "isApproved", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "settleOption",
      inputs: [
        {
          name: "_params",
          type: "tuple",
          internalType: "struct DopexV2OptionMarketV2.SettleOptionParams",
          components: [
            {
              name: "optionId",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "swapper",
              type: "address[]",
              internalType: "contract ISwapper[]",
            },
            {
              name: "swapData",
              type: "bytes[]",
              internalType: "bytes[]",
            },
            {
              name: "liquidityToSettle",
              type: "uint256[]",
              internalType: "uint256[]",
            },
          ],
        },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "settlers",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "supportsInterface",
      inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
      outputs: [{ name: "result", type: "bool", internalType: "bool" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "symbol",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "tokenURI",
      inputs: [{ name: "id", type: "uint256", internalType: "uint256" }],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "tokenURIFetcher",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view",
    },
    {
      type: "function",
      name: "transferFrom",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "to", type: "address", internalType: "address" },
        { name: "id", type: "uint256", internalType: "uint256" },
      ],
      outputs: [],
      stateMutability: "payable",
    },
    {
      type: "function",
      name: "transferOwnership",
      inputs: [{ name: "newOwner", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "updateAddress",
      inputs: [
        { name: "_feeTo", type: "address", internalType: "address" },
        {
          name: "_tokeURIFetcher",
          type: "address",
          internalType: "address",
        },
        { name: "_dpFee", type: "address", internalType: "address" },
        {
          name: "_optionPricing",
          type: "address",
          internalType: "address",
        },
        { name: "_settler", type: "address", internalType: "address" },
        { name: "_statusSettler", type: "bool", internalType: "bool" },
        { name: "_pool", type: "address", internalType: "address" },
        { name: "_statusPools", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "updateExerciseDelegate",
      inputs: [
        { name: "_delegateTo", type: "address", internalType: "address" },
        { name: "_status", type: "bool", internalType: "bool" },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        {
          name: "owner",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "account",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "id",
          type: "uint256",
          indexed: true,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ApprovalForAll",
      inputs: [
        {
          name: "owner",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "operator",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "isApproved",
          type: "bool",
          indexed: false,
          internalType: "bool",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogExerciseOption",
      inputs: [
        {
          name: "user",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "tokenId",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "totalProfit",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "totalAssetRelocked",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogMintOption",
      inputs: [
        {
          name: "user",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "tokenId",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "isCall",
          type: "bool",
          indexed: false,
          internalType: "bool",
        },
        {
          name: "premiumAmount",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "totalAssetWithdrawn",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "protocolFees",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogOptionsMarketInitialized",
      inputs: [
        {
          name: "primePool",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "optionPricing",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "dpFee",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "callAsset",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "putAsset",
          type: "address",
          indexed: false,
          internalType: "address",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogSettleOption",
      inputs: [
        {
          name: "user",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "tokenId",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogSplitOption",
      inputs: [
        {
          name: "user",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "tokenId",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "newTokenId",
          type: "uint256",
          indexed: false,
          internalType: "uint256",
        },
        {
          name: "to",
          type: "address",
          indexed: false,
          internalType: "address",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogUpdateAddress",
      inputs: [
        {
          name: "tokeURIFetcher",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "dpFee",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "optionPricing",
          type: "address",
          indexed: false,
          internalType: "address",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "LogUpdateExerciseDelegate",
      inputs: [
        {
          name: "owner",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "delegate",
          type: "address",
          indexed: false,
          internalType: "address",
        },
        {
          name: "status",
          type: "bool",
          indexed: false,
          internalType: "bool",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "OwnershipTransferred",
      inputs: [
        {
          name: "previousOwner",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "newOwner",
          type: "address",
          indexed: true,
          internalType: "address",
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        {
          name: "from",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "to",
          type: "address",
          indexed: true,
          internalType: "address",
        },
        {
          name: "id",
          type: "uint256",
          indexed: true,
          internalType: "uint256",
        },
      ],
      anonymous: false,
    },
    { type: "error", name: "AccountBalanceOverflow", inputs: [] },
    { type: "error", name: "BalanceQueryForZeroAddress", inputs: [] },
    {
      type: "error",
      name: "DopexV2OptionMarket__ArrayLenMismatch",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__EmptyOption",
      inputs: [],
    },
    { type: "error", name: "DopexV2OptionMarket__IVNotSet", inputs: [] },
    {
      type: "error",
      name: "DopexV2OptionMarket__InvalidPool",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__MaxCostAllowanceExceeded",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__MaxOptionBuyReached",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__NotApprovedSettler",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__NotEnoughAfterSwap",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__NotIVSetter",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__NotOwnerOrDelegator",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__NotValidStrikeTick",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__OptionExpired",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__OptionNotExpired",
      inputs: [],
    },
    {
      type: "error",
      name: "DopexV2OptionMarket__PoolNotApproved",
      inputs: [],
    },
    { type: "error", name: "NotOwnerNorApproved", inputs: [] },
    { type: "error", name: "T", inputs: [] },
    { type: "error", name: "TokenAlreadyExists", inputs: [] },
    { type: "error", name: "TokenDoesNotExist", inputs: [] },
    { type: "error", name: "TransferFromIncorrectOwner", inputs: [] },
    {
      type: "error",
      name: "TransferToNonERC721ReceiverImplementer",
      inputs: [],
    },
    { type: "error", name: "TransferToZeroAddress", inputs: [] },
  ],
};

const executorConfig = {
  abi: [
    {
      inputs: [
        {
          internalType: "uint32",
          name: "_dstEid",
          type: "uint32",
        },
      ],
      name: "dstConfig",
      outputs: [
        {
          internalType: "uint64",
          name: "",
          type: "uint64",
        },
        {
          internalType: "uint16",
          name: "",
          type: "uint16",
        },
        {
          internalType: "uint128",
          name: "",
          type: "uint128",
        },
        {
          internalType: "uint128",
          name: "",
          type: "uint128",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ],
};

export {
  swapContractConfig,
  tokenContractConfig,
  nftContractConfig,
  uniswapv3,
  nftUtils,
  dopexV2OptionMarket,
  executorConfig,
};
