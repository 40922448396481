import { Header, Navbar } from '../components';


export const Tutorial = () => {
    return (
        <>
            <Navbar />
            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container tutorial-wrapper mt-4 mb-4">
                            <video
                                src={"/tutorial.mp4"}
                                title={"Tutorial"}
                                controls
                            ></video >
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
