import { BridgeListing, Header, Navbar } from '../components';


export const Bridge = () => {
    return (
        <>
            <Navbar />
            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <div className="content">
                        <BridgeListing />
                    </div>
                </div>
            </div>
        </>
    );
};
