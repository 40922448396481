import { Dispatch, SetStateAction, useCallback } from "react";
import { TokenAddress } from "../types";
import { useWriteContract } from "wagmi";
import { chainIdToEndpoint, otcAddress, swapContractConfig } from "../config";
import { serializeListing } from "../utilities/serialize";
import { useActiveWeb3React } from "./useActiveWeb3React";
import useNotification from "../utilities/notificationUtils";
import { MaxUint256 } from "ethers";

export function useBridgeListingFn(
  fromToken: TokenAddress,
  toToken: TokenAddress,
  amountFrom: bigint,
  amountTo: bigint,
  toChain: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  isCollectionOffer: boolean
) {
  const { account, chainId, publicClient } = useActiveWeb3React();
  const { writeContractAsync } = useWriteContract();
  const { showSuccess, showError } = useNotification();

  return useCallback(async () => {
    try {
      setLoading(true);
      const destinationEndpoint = chainIdToEndpoint[toChain];
      const listing = serializeListing(
        account as string,
        fromToken,
        toToken,
        amountFrom,
        isCollectionOffer ? MaxUint256 : amountTo,
        destinationEndpoint
      );
      const tx = await writeContractAsync({
        abi: swapContractConfig.abi,
        args: [listing],
        address: otcAddress[chainId] as any,
        functionName: "createListing",
        account: account,
      });
      await publicClient?.waitForTransactionReceipt({ hash: tx });
      showSuccess("Successfully created listing");
    } catch (error) {
      console.log(error);
      showError("Error occurred while creating listing");
    } finally {
      setLoading(false);
    }
  }, [
    fromToken,
    toToken,
    amountFrom,
    amountTo,
    account,
    writeContractAsync,
    chainId,
    publicClient,
    showSuccess,
    showError,
    setLoading,
    toChain,
    isCollectionOffer,
  ]);
}
