import { Grid, Typography } from "@mui/material";

import { Header, ListingCard, Navbar } from "../components";
import { useAllListings } from "../../hooks/useAllListings";
import { ListingModal } from "../components/ListingModal";
import { useState } from "react";
import { OTCListing } from "@/types";

export const Marketplace = () => {
  const { data: listings, isLoading, refetch } = useAllListings();

  const [selectedListing, setSelectedListing] = useState<
    OTCListing | undefined
  >();

  return (
    <>
      <Navbar />

      <div className="wrapper">
        <Header />
        <div className="content-wrapper">
          <div className="content">
            <div className="logo d-flex flex-wrap">
              <div className="brick-row">
                <span className="brick-letter blue-brick-letter">O</span>
                <span className="brick-letter green-brick-letter">V</span>
                <span className="brick-letter black-brick-letter">E</span>
                <span className="brick-letter red-brick-letter">R</span>
              </div>
              <span className="brick-letter logo-brick-spacing"> </span>
              <div className="brick-row">
                <span className="brick-letter red-brick-letter">T</span>
                <span className="brick-letter green-brick-letter">H</span>
                <span className="brick-letter blue-brick-letter">E</span>
              </div>
              <span className="brick-letter logo-brick-spacing"> </span>
              <div className="brick-row">
                <span className="brick-letter blue-brick-letter">C</span>
                <span className="brick-letter green-brick-letter">O</span>
                <span className="brick-letter black-brick-letter">U</span>
                <span className="brick-letter red-brick-letter">N</span>
                <span className="brick-letter black-brick-letter">T</span>
                <span className="brick-letter green-brick-letter">E</span>
                <span className="brick-letter blue-brick-letter">R</span>
              </div>
            </div>

            <div>
              <span className="brick-letter"></span>
            </div>
            {selectedListing && (
              <ListingModal
                open={selectedListing !== undefined}
                listing={selectedListing}
                refetch={refetch}
                onClose={() => setSelectedListing(undefined)}
                setSelectedListing={setSelectedListing}
              />
            )}
            {listings.length === 0 && (
              <Typography variant="body1">
                <div className="d-flex flex-wrap">
                  {"No listings available".split("").map((char, index) => {
                    // Check if the character is a space, and if so, render a space brick
                    return char === " " ? (
                      <span key={index} className="brick-letter"></span>
                    ) : (
                      <span key={index} className="brick-letter">
                        {char}
                      </span>
                    );
                  })}
                </div>
              </Typography>
            )}
            <Grid container spacing={4} className="p-2">
              {listings.reverse().map((listing, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <ListingCard
                    setSelectedListing={setSelectedListing}
                    listing={listing}
                    refetch={refetch}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
