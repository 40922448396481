import { useTokenBalance } from "../../hooks/useTokenBalance";
import { Box, Button, Grid, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { formatUnits } from "ethers";

interface Props {
  token: any;
  handleTokenAmountChanged: any;
}

export function TokenBalance({ handleTokenAmountChanged, token }: Props) {
  const { data: balance } = useTokenBalance(token.address);

  const formattedBalance = new BigNumber(
    formatUnits(balance || 0, token.decimals)
  );
  return (
    <Grid item xs={12}>
      <Box display="flex" gap={2} justifyContent="flex-end" alignItems="center">
        <Typography>Balance:</Typography>
        <Typography>{formattedBalance.toFixed(4)}</Typography>
        <Button
          variant="contained"
          onClick={(e) => {
            handleTokenAmountChanged(e, formattedBalance.toNumber());
          }}>
          Max
        </Button>
      </Box>
    </Grid>
  );
}
