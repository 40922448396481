import { Config, createConfig, http } from "wagmi";
import { arbitrum, arbitrumSepolia, Chain, sanko } from "wagmi/chains";
import { ChainEnum } from "./chains";

export * from "./smartContractConfig";
export * from "./tokens";
export * from "./chains";

export const chainToHandler: Record<number, Record<string, string>> = {
  [ChainEnum.SankoTestnet]: {
    ERC20: "0x124c7eAAd5A8f6781c30B5FF23915e818743c6fe",
    ERC721: "0xd1539Ed07226dd8Dd7D50Bd0CB2F54C79c9EA9c1",
  },
  [ChainEnum.ArbitrumTestnet]: {
    ERC20: "0xCAC01292808b0ad94A2a9EB3cA4Db2237E03CAaf",
    ERC721: "0xeeaAC24d0b777917Be0B9B5E5075b2d47366b596",
  },
  [ChainEnum.Arbitrum]: {
    ERC20: "0x9755ED1b3D2af348B7d621b79A01521D5D55AF61",
    ERC721: "0xc4636e2d9C40D2760b9D8B6e575Ce626B940FB66",
  },
  [ChainEnum.Sanko]: {
    ERC20: "0x35c51Ff905eA0cd9DE3b65F83fA67a625699d3A0",
    ERC721: "0x311943b28d4030191531Ee4a5f180915E1fA0b5E",
  },
};

export const chainToNFTUtils: Record<number, string> = {
  [ChainEnum.Arbitrum]: "0x8669C98d22B3505e48F31F35233C1fA7c70AB4b4",
  [ChainEnum.Sanko]: "0xC155Ca4A3C8510c4893D20AbF0B1435b11a400F5",
};

export const otcAddress: Record<number, string> = {
  [ChainEnum.SankoTestnet]: "0x660091e2e131550552a6aCb7E0FbcF14AcBD0339",
  [ChainEnum.ArbitrumTestnet]: "0x1d1Cb54f6636faCE499C9C27e5D10C92C00CF242",
  [ChainEnum.Sanko]: "0xd05a568748BFC4102Ce8222d96b2Af9250A2FFd8",
  [ChainEnum.Arbitrum]: "0x8C203A05E0E2daD15A3A2f8019bB04af157207A4",
};

export const executorAddress: Record<number, string> = {
  [ChainEnum.Sanko]: "0xc097ab8CD7b053326DFe9fB3E3a31a0CCe3B526f",
  [ChainEnum.Arbitrum]: "0x31CAe3B7fB82d847621859fb1585353c5720660D",
};

export const collectionToChain: Record<string, number> = {
  "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B": ChainEnum.Arbitrum,
  "0xE3351CE33689dc444B1a45B8f8F447A181D57227": ChainEnum.Sanko,
  "0x53c302b14382dE9A8D525110560c99bf32Ab0968": ChainEnum.Arbitrum,
  "0xE40A99f9E492737903Dc79dA163D6d45d0158B5d": ChainEnum.Sanko,
};

export const sankoTestnet = {
  id: 1992,
  name: "Sanko Testnet",
  nativeCurrency: { name: "DMT", symbol: "DMT", decimals: 18 },
  rpcUrls: {
    default: {
      http: ["https://sanko-arb-sepolia.rpc.caldera.xyz/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "Sanko Explorer",
      url: "https://explorer.sanko.xyz",
    },
  },
  contracts: {
    multicall3: {
      address: "0xB32d192e07727A0F6f48e721791aa4e02cd292e1",
    },
  },
  testnet: false,
} as const satisfies Chain;

export const supportedChains = [sanko, arbitrum];

export const chainToConfig: Record<string, Config> = {
  [ChainEnum.SankoTestnet]: createConfig({
    chains: [sankoTestnet],
    transports: {
      [sankoTestnet.id]: http(sankoTestnet.rpcUrls.default.http[0]),
    },
  }),
  [ChainEnum.ArbitrumTestnet]: createConfig({
    chains: [arbitrumSepolia],
    transports: {
      [arbitrumSepolia.id]: http(arbitrumSepolia.rpcUrls.default.http[0]),
    },
  }),
  [ChainEnum.Sanko]: createConfig({
    chains: [
      {
        ...sanko,
        contracts: {
          multicall3: {
            address: "0xB32d192e07727A0F6f48e721791aa4e02cd292e1",
          },
        },
      },
    ],
    transports: {
      [sanko.id]: http(sanko.rpcUrls.default.http[0]),
    },
  }),
  [ChainEnum.Arbitrum]: createConfig({
    chains: [arbitrum],
    transports: {
      [arbitrum.id]: http(arbitrum.rpcUrls.default.http[0]),
    },
  }),
};

export const chainIdToChainMapping: Record<number, Chain> = {
  [ChainEnum.SankoTestnet]: sankoTestnet,
  [ChainEnum.ArbitrumTestnet]: arbitrumSepolia,
  [ChainEnum.Arbitrum]: arbitrum,
  [ChainEnum.Sanko]: sanko,
};

export const endpointsToChainId: Record<string, number> = {
  40278: ChainEnum.SankoTestnet,
  40143: ChainEnum.ArbitrumTestnet,
  30278: ChainEnum.Sanko,
  30110: ChainEnum.Arbitrum,
};

export const chainIdToEndpoint: Record<string, number> = {
  [ChainEnum.SankoTestnet]: 40278,
  [ChainEnum.ArbitrumTestnet]: 40143,
  [ChainEnum.Sanko]: 30278,
  [ChainEnum.Arbitrum]: 30110,
};

export const chainToGasLimit: Record<string, number> = {
  [ChainEnum.Arbitrum]: 500000,
  [ChainEnum.Sanko]: 500000,
};
