import { Chain } from "wagmi/chains";
import {
  chainToConfig,
  chainToHandler,
  collectionToChain,
  endpointsToChainId,
  otcAddress,
  supportedChains,
  swapContractConfig,
} from "../config";
import { readContract, multicall } from "@wagmi/core";
import { useQuery } from "@tanstack/react-query";
import { OTCListing } from "../types";
import { MaxUint256, ZeroAddress } from "ethers";
import { getCollectionFetchFn } from "./useNFTBalances";
import { Listing } from "@/theme2/features";

const BATCH_SIZE = 500;

async function getListingsForChain(chain: Chain) {
  try {
    const chainConfig = chainToConfig[chain.id];
    const nextListingId = (await readContract(chainConfig, {
      functionName: "nextListingId",
      address: otcAddress[chain.id] as any,
      abi: swapContractConfig.abi,
      args: [],
    })) as unknown as bigint;

    const batches = Math.ceil(Number(nextListingId.toString()) / BATCH_SIZE);

    const calls = new Array(batches).fill({}).map((_, index) => ({
      address: otcAddress[chain.id] as any,
      abi: swapContractConfig.abi as any,
      functionName: "getAllUnclaimedListings",
      args: [index * BATCH_SIZE, index * BATCH_SIZE + BATCH_SIZE],
    }));
    const results = await multicall(chainConfig, {
      contracts: calls,
    });
    return results.flatMap((data) => {
      if (data.status === "success") {
        //@ts-ignore
        return data.result[0].map((val, index) => ({
          ...val,
          //@ts-ignore
          listingId: Number(data.result[1][index]),
        }));
      }
      return [];
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getAllListings() {
  try {
    const results = await Promise.all(
      supportedChains.map((val) => getListingsForChain(val))
    );

    const filteredListings = results.flatMap((val, index) =>
      val
        .map((listing: any) => ({
          ...listing,
          srcChain: supportedChains[index].id,
          dstChain: endpointsToChainId[listing.destinationEndpoint],
        }))
        .filter((val) => val.seller !== ZeroAddress)
    );

    let keysToCollectionListing: Record<string, Set<number>> = {};
    let keysToHandler: Record<string, string> = {};
    let keysToChainId: Record<string, number> = {};

    filteredListings.forEach((listing, index) => {
      const srcIsNft =
        chainToHandler[listing.srcChain]["ERC721"] ===
        listing.tokenForSale.handler;
      const destIsNFT =
        chainToHandler[listing.dstChain]["ERC721"] ===
        listing.tokenToReceive.handler;
      if (srcIsNft) {
        if (!keysToCollectionListing[listing.tokenForSale.contractAddress]) {
          keysToCollectionListing[listing.tokenForSale.contractAddress] =
            new Set();
        }
        if (!keysToHandler[listing.tokenForSale.contractAddress]) {
          keysToHandler[listing.tokenForSale.contractAddress] =
            listing.tokenForSale.handler;
        }
        if (!keysToChainId[listing.tokenForSale.contractAddress]) {
          keysToChainId[listing.tokenForSale.contractAddress] =
            listing.srcChain;
        }
        keysToCollectionListing[listing.tokenForSale.contractAddress].add(
          listing.tokenForSale.value
        );
      }
      if (destIsNFT) {
        if (!keysToCollectionListing[listing.tokenToReceive.contractAddress]) {
          keysToCollectionListing[listing.tokenToReceive.contractAddress] =
            new Set();
        }
        if (!keysToHandler[listing.tokenToReceive.contractAddress]) {
          keysToHandler[listing.tokenToReceive.contractAddress] =
            listing.tokenToReceive.handler;
        }
        if (!keysToChainId[listing.tokenToReceive.contractAddress]) {
          keysToChainId[listing.tokenToReceive.contractAddress] =
            listing.dstChain;
        }
        keysToCollectionListing[listing.tokenToReceive.contractAddress].add(
          listing.tokenToReceive.value
        );
      }
    });

    let collectionToValueToData: Record<string, Record<number, any>> = {};

    for (var collectionAddress of Object.keys(keysToCollectionListing)) {
      const fn = getCollectionFetchFn(collectionAddress);
      if (fn) {
        const fetchedData = await fn(
          Array.from(keysToCollectionListing[collectionAddress]),
          keysToHandler[collectionAddress],
          keysToChainId[collectionAddress],
          collectionAddress
        );

        //eslint-disable-next-line no-loop-func
        fetchedData.map((val) => {
          if (!collectionToValueToData[collectionAddress]) {
            collectionToValueToData[collectionAddress] = {};
          }
          return (collectionToValueToData[collectionAddress][
            Number(val.tokenId)
          ] = val);
        });
      }
    }

    return filteredListings.map((listing) => ({
      ...listing,
      extraBuyInfo:
        collectionToValueToData?.[listing.tokenForSale.contractAddress]?.[
          listing.tokenForSale.value
        ],
      extraSellInfo:
        collectionToValueToData?.[listing.tokenToReceive.contractAddress]?.[
          listing.tokenToReceive.value
        ],
    }));
  } catch (error) {
    console.log(error);
    return [];
  }
}

export function useAllListings() {
  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery<OTCListing[]>({
    queryKey: [],
    queryFn: getAllListings,
  });

  return { data: data, isLoading, refetch };
}
