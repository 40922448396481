import React, { createContext, ReactNode, useContext, useState } from 'react';
import { TokenAddress } from '../types';

interface ListingContextType {
    emojiVisible: boolean;
    showEmoji: () => Promise<void>;
}

const ListingContext = createContext<ListingContextType | undefined>(undefined);

export const ListingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [emojiVisible, setEmojiVisible] = useState<boolean>(false);

    const showEmoji = async () => {
        setEmojiVisible(true);
        setTimeout(() => setEmojiVisible(false), 10000);
    };

    return (
        <ListingContext.Provider value={{ emojiVisible, showEmoji }}>
            {children}
        </ListingContext.Provider>
    );
};

export const useListing = () => {
    const context = useContext(ListingContext);
    if (context === undefined) {
        throw new Error("useListing must be used within a ListingProvider");
    }
    return context;
};
