import { IconButton } from '@mui/material';
import { ChainEnum, chains } from '../../config';
import { useWallet } from '../../context/WalletContext';
import { useListing } from '../../context/ListingContext';

export const Header: React.FC = () => {
    const { network, changeNetwork } = useWallet();
    const { emojiVisible } = useListing();
    return (
        <>
            <div className="header-wrapper">
                <div className="header">
                    {
                        true ? <img className="minesweeper" src="/Minesweeper.webp" alt="Minesweeper"
                            onClick={() => {
                                window.location.href = "https://smoovie-phone-usdsp-defi-x-gamef.gitbook.io/smoovie-phone-usdsp-defi-x-gamefi/usdsp-ecosystem/markdown"
                            }}
                        /> : <span></span>
                    }
                    <div className="buttons">
                        {chains.map((chain, i) => (
                            <IconButton key={i} onClick={() => changeNetwork(chain.chainId)}>
                                <img className={network === chain.chainId ? 'active' : ''} src={chain?.logoURI} alt={chain?.name} />
                            </IconButton>
                        ))}
                        <IconButton key={-1} onClick={() => window.location.href = 'https://smoovie-phone-usdsp-defi-x-gamef.gitbook.io/smoovie-phone-usdsp-defi-x-gamefi/getting-started/quickstart'}>
                            <img src={'/images/file.png'} />
                        </IconButton>
                        <IconButton key={-2} onClick={() => window.location.href = 'https://smooviephone.com'}>
                            <img src={'/images/info.png'} />
                        </IconButton>
                    </div>
                </div>

            </div>
        </>
    );
};
