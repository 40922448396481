import * as ethers from "ethers";

const connectWallet = async () => {
    try {
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        return signer;
    } catch (error) {
        throw error;
    }
}

export {
    connectWallet
}