import { useQuery } from "@tanstack/react-query";
import { useActiveWeb3React } from "../useActiveWeb3React";
import { chainToNFTUtils, nftUtils } from "../../config";
import { getLmPositionData } from "./lm";
import { getBunsMetadata } from "./buns";
import { TokenAddress } from "../../types";
import { ZeroAddress } from "ethers";
import { getIPFSMetadata } from "./ipfs";

type FetchFn = (
  ids: (number | bigint)[],
  account?: string,
  chainId?: number,
  nftAddress?: string
) => Promise<any[]>;

export function getCollectionFetchFn(collection: string): FetchFn {
  return ({
    "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B": getLmPositionData,
    // "0xE3351CE33689dc444B1a45B8f8F447A181D57227": getBunsMetadata,
    //Test Buns - delete in prod
    "0x53c302b14382dE9A8D525110560c99bf32Ab0968": getBunsMetadata,
    "0xE40A99f9E492737903Dc79dA163D6d45d0158B5d": getBunsMetadata,
  }?.[collection] || getIPFSMetadata) as any;
}

const collectionToTotalSupply: Record<string, number> = {
  "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B": 0,
  "0xE3351CE33689dc444B1a45B8f8F447A181D57227": 4444,
  //Test Buns - delete in prod
  "0xc447597EfC493E36cCCA16707559Fb7238D0367c": 5000,
  //Test Buns - delete in prod
  "0x53c302b14382dE9A8D525110560c99bf32Ab0968": 6,
  "0xE40A99f9E492737903Dc79dA163D6d45d0158B5d": 6,
};

export function useOwnedNfts(
  nftAddress: string,
  chainId: number,
  account: string
) {
  const { publicClient } = useActiveWeb3React();

  return useQuery({
    queryKey: [account, nftAddress || ZeroAddress, chainId, publicClient],
    queryFn: async () => {
      try {
        if (!account || !nftAddress || !chainId || !publicClient) return [];
        //@ts-ignore
        const collectionFetchFn = getCollectionFetchFn(nftAddress);

        const totalSupply = collectionToTotalSupply[nftAddress] || 20000;
        const ownedIds =
          account === ZeroAddress
            ? []
            : ((await publicClient.readContract({
                abi: nftUtils.abi,
                args: [nftAddress, account, totalSupply],
                address: chainToNFTUtils[chainId] as any,
                functionName: "viewOwnedIds",
                account: account as any,
              })) as unknown as bigint[]);
        return await collectionFetchFn(ownedIds, account, chainId, nftAddress);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
}

export function useNftInfo(
  nft: TokenAddress,
  id: number,
  shouldUpdate: boolean
) {
  const { account } = useActiveWeb3React();

  return useQuery({
    queryKey: [account, nft, id, shouldUpdate],
    queryFn: async () => {
      try {
        if (!account || !nft || !nft.isNft || !shouldUpdate) return undefined;
        const collectionFetchFn = getCollectionFetchFn(nft.address);
        if (!collectionFetchFn) return [];

        return (await collectionFetchFn([id]))[0];
      } catch (error) {
        return undefined;
      }
    },
  });
}
