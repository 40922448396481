import { serializeNFTAttributes } from "../../utilities/serialize";
import { chainToConfig, nftContractConfig } from "../../config";
import { multicall } from "@wagmi/core";

const getIPFSUrl = (url: string) => {
  if (url.includes("ipfs://")) {
    return `https://ipfs.io/ipfs/${url.split("ipfs://")[1]}`;
  }
  if (url.includes("ar:/")) {
    return `https://arweave.net/${url.split("ar://")[1]}`;
  }
  return url;
};

export const getIPFSMetadata = async (
  id: any[],
  _: string,
  chainId: number,
  nftAddress: string
): Promise<any[]> => {
  try {
    const chainConfig = chainToConfig[chainId];
    const calls = id.map((val) => ({
      functionName: "tokenURI",
      address: nftAddress as any,
      abi: nftContractConfig.abi as any,
      args: [val],
    }));
    const results = await multicall(chainConfig, {
      contracts: calls,
    });
    const metadataCalls = results.map(async (data, index) => {
      if (data.status === "success") {
        let res = data.result as string;
        //@ts-ignore
        res = getIPFSUrl(data.result);
        const result = await fetch(res, {
          signal: AbortSignal.timeout(5000),
        });
        return await result.json();
      }
      return new Promise((res) => res({ tokenId: id[index] }));
    });
    const res = await Promise.all(metadataCalls);
    return res.map((val, index) => {
      return {
        tokenId: Number(id[index]),
        ...serializeNFTAttributes(val?.attributes || []),
        image: val?.image ? getIPFSUrl(val?.image) : "",
      };
    });
  } catch (error) {
    console.log(error);
    return id.map((val) => ({ tokenId: Number(val) }));
  }
};
