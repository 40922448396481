import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  tokenAddresses as allTokens,
  chainIdToChainMapping,
  chains,
  chainToHandler,
} from "../../config";
import { Chain, TokenAddress } from "../../types";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import {
  ApprovalState,
  useApproveNFTCollection,
  useApproveToken,
} from "../../hooks/useApprove";
import { useBridgeListingFn } from "../../hooks/useBridgeListing";
import TokenSelect from "../../shared/tokenSelect";
import { useTokenBalance } from "../../hooks/useTokenBalance";
import { useNftExists } from "../../hooks/useNFTExists";
import { parseUnits } from "ethers";

export const BridgeListing: React.FC = () => {
  const { chainId, account } = useActiveWeb3React();
  const [fromToken, setFromToken] = useState<TokenAddress | null>(null);
  const [toToken, setToToken] = useState<TokenAddress | null>(null);
  const [toChain, setToChain] = useState<Chain | null>(null);
  const [amountFrom, setAmountFrom] = useState<number>(0);
  const [amountTo, setAmountTo] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const srcChain = chainIdToChainMapping[chainId];

  const isToNft = toToken?.isNft;
  const [collectionOffer, setCollectionOffer] = useState(false);

  const fromTokenAddresses = allTokens?.filter((x) => x?.chainId === chainId);
  const toTokenAddresses = allTokens?.filter(
    (x) => x?.chainId === toChain?.chainId
  );

  let approvalState;
  let approveFn;
  let isLoadingApproval;
  const amountFromBigInt = parseUnits(
    amountFrom.toString(),
    fromToken?.isNft ? 0 : fromToken?.decimals
  );
  const amountToBigInt = parseUnits(
    amountTo.toString(),
    toToken?.isNft ? 0 : toToken?.decimals
  );

  const nftApproval: [string, any, boolean] = useApproveNFTCollection(
    fromToken?.address,
    chainToHandler[chainId]["ERC721"]
  );
  const tokenApproval: [string, any, boolean] = useApproveToken(
    fromToken?.address,
    amountFromBigInt,
    chainToHandler[chainId]["ERC20"]
  );
  [approvalState, approveFn, isLoadingApproval] = fromToken?.isNft
    ? nftApproval
    : tokenApproval;

  const { data: tokenBalance = 0 } = useTokenBalance(fromToken?.address);
  const { data: nftExists = false } = useNftExists(toToken, amountToBigInt);

  const bridgeFn = useBridgeListingFn(
    fromToken as TokenAddress,
    toToken as TokenAddress,
    amountFromBigInt,
    amountToBigInt,
    toChain?.chainId || 0,
    setLoading,
    collectionOffer
  );

  const handleFromTokenChange = (event: any, value: TokenAddress | null) => {
    setAmountFrom(0);
    setCollectionOffer(false);
    setFromToken(value);
  };

  const handleToTokenChange = (event: any, value: TokenAddress | null) => {
    setAmountTo(0);
    setToToken(value);
    setCollectionOffer(false);
  };

  const handleToChainChange = (event: SelectChangeEvent<string>) => {
    const selectedChain =
      chains.find((chain) => chain.name === event.target.value) || null;
    setToChain(selectedChain);
    setCollectionOffer(false);
  };

  const handleAmountFromChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    setAmountFrom(parseFloat(event.target.value) || parseFloat(value) || 0);
  };

  const handleAmountToChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    setAmountTo(parseFloat(event.target.value) || parseFloat(value) || 0);
  };

  useEffect(() => {
    setAmountTo(0);
    setAmountFrom(0);
    setFromToken(null);
    setToToken(null);
  }, [chainId, account]);

  const errors =
    fromToken && !fromToken.isNft && tokenBalance < amountFromBigInt
      ? "Insufficient Balance"
      : toToken && toToken?.isNft && !nftExists && !collectionOffer
      ? "NFT ID doesnt exist"
      : fromToken && !fromToken.isNft && Number(amountFromBigInt) === 0
      ? "Amount must be greater than 0"
      : fromToken && fromToken.isNft && Number(amountFromBigInt) === 0
      ? "Select an NFT ID"
      : toToken && !toToken.isNft && Number(amountToBigInt) === 0
      ? "Amount must be greater than 0"
      : false;

  const disabled =
    !fromToken || !toToken || Boolean(errors) || loading || isLoadingApproval;

  return (
    <Paper className="container bridge-listing p-4 mt-4 mb-4">
      <Typography className="note mt-1 mb-4" variant="h4" component="p">
        Create bridge listing
      </Typography>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>From Chain</InputLabel>
              <Select
                value={chainIdToChainMapping[chainId]?.name}
                label="From Chain"
                //@ts-ignore
                onChange={handleFromTokenChange}
                fullWidth>
                <MenuItem key={srcChain?.id} value={srcChain?.name}>
                  {srcChain?.name}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <TokenSelect
            placeholder={fromToken?.isNft ? "Token Id" : "Amount"}
            token={fromToken}
            handleTokenChange={handleFromTokenChange}
            tokenAddresses={fromTokenAddresses}
            label={fromToken?.isNft ? "Select NFT" : `Select Token`}
            handleAmountChanged={handleAmountFromChange}
            amount={amountFrom}
          />
          <Grid item xs={12} md={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>To Chain</InputLabel>
              <Select
                value={toChain ? toChain.name : ""}
                onChange={handleToChainChange}
                label="To Chain"
                fullWidth>
                {chains.map((chain) => (
                  <MenuItem key={chain.id} value={chain.name}>
                    {chain.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {isToNft && (
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center">
                <Checkbox
                  checked={collectionOffer}
                  onClick={() => setCollectionOffer(!collectionOffer)}
                />
                <Typography>Collection Offer</Typography>
              </Box>
            )}
          </Grid>
          <TokenSelect
            context="to"
            placeholder={toToken?.isNft ? "Token Id" : "Amount"}
            token={toToken}
            handleTokenChange={handleToTokenChange}
            tokenAddresses={toTokenAddresses}
            label={toToken?.isNft ? "Select NFT" : `Select Token`}
            handleAmountChanged={handleAmountToChange}
            amount={collectionOffer ? "-" : amountTo}
          />
          <Grid item xs={12} md={12}>
            <Button
              sx={{ width: "100%" }}
              disabled={disabled}
              onClick={
                approvalState === ApprovalState.NOT_APPROVED
                  ? approveFn
                  : bridgeFn
              }
              className="button mt-4"
              variant="contained">
              {errors
                ? errors
                : approvalState === ApprovalState.NOT_APPROVED
                ? "Approve"
                : "Create"}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Typography className="note mt-5" variant="body2" component="p">
        Please note each swap carries a 0.4% protocol fee
      </Typography>
    </Paper>
  );
};
