import { useActiveWeb3React } from "../hooks/useActiveWeb3React";
import { Header, Navbar } from "../theme2/components";
import { Typography } from "@mui/material";
import { ReactNode } from "react";

interface SimplePage {
  children: ReactNode;
}

const SimplePage: React.FC<SimplePage> = ({ children }) => {
  const { account } = useActiveWeb3React();
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <Header />
        <div className="content-wrapper">
          <div className="content">
            {account ? (
              children
            ) : (
              <Typography
                variant="h4"
                sx={{ marginBottom: 2, textAlign: "center" }}>
                Connect your wallet!
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SimplePage;
