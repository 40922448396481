import { Box, Chip } from "@mui/material";

export function NftAttributes({ attributes }: any) {
  return (
    <Box display="flex" width="100%" gap={2} flexWrap="wrap">
      {Object.keys(attributes)
        .slice(0, 5)
        .map((val) =>
          val?.toLowerCase() === "image" ? (
            <></>
          ) : (
            <Chip
              key={`${val} ${attributes[val]}`}
              variant="filled"
              label={`${val} ${attributes[val]}`}
            />
          )
        )}
    </Box>
  );
}
