import { Header, Navbar, CreateListing as CreateListing } from '../components';


export const Listing = () => {
    return (
        <>
            <Navbar />
            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <div className="content">
                        <CreateListing />
                    </div>
                </div>
            </div>
        </>
    );
};
