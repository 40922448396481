export const currentMarket = {
  address: "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B",
  callToken: {
    address: "0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8",
    decimals: 18,
    symbol: "DMT",
  },
  putToken: {
    address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    decimals: 18,
    symbol: "WETH",
  },
  feeStrategy: "0x0D2fCCDA638225dAb6E2c713760A8DB211B95Ad3",
  token0: {
    address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    decimals: 18,
    symbol: "WETH",
  },
  token1: {
    address: "0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8",
    decimals: 18,
    symbol: "DMT",
  },
  inversePrice: true,
  pools: [
    {
      feeBps: 10000,
      identifier: "uniswap",
      tickSpacing: 200,
      address: "0xE03Da31Bc2AA7e45D37a7681Aa258F095E3e77E1",
      handler: "0xB09B7a74523E783b71b19534BD1C5f1f8733fF8e",
      name: "Uniswap V3",
    },
  ],
  ticker: "DMT-WETH",
  pricing: "0x5de5c21d4550121A66a927f5e8eEe6c8D007b77B",
  primePool: {
    feeBps: 10000,
    identifier: "uniswap",
    tickSpacing: 200,
    address: "0xE03Da31Bc2AA7e45D37a7681Aa258F095E3e77E1",
    handler: "0xB09B7a74523E783b71b19534BD1C5f1f8733fF8e",
    name: "Uniswap V3",
  },
};
