import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Bridge, Listing, Marketplace, Tutorial } from "./theme2/features";
import Providers from "./providers";
import { useWallet } from "./context/WalletContext";
import ChatMessenger from "./ChatMessenger";
import ChatArea from "./ChatArea";
import SimplePage from "./components/SimplePage";

function App() {
  const { account } = useWallet();
  return (
    <Providers>
      <Router>
        <Routes>
          <Route path="/" element={<Marketplace />} />
          <Route path="/create-listing" element={<Listing />} />
          <Route path="/tutorial-page" element={<Tutorial />} />
          <Route path="/bridge" element={<Bridge />} />
          <Route path="/chats" element={account ? <ChatMessenger /> : <SimplePage children />} />
          <Route path="/chat-area/:roomId" element={account ? <ChatArea /> : <SimplePage children />} />
        </Routes>
      </Router>
    </Providers>
  );
}

export default App;
