import { OTCListing } from "../../types";
import { Dialog } from "@mui/material";
import { ListingCard } from "./ListingCard";
import {
  useApproveNFTCollection,
  useApproveToken,
} from "../../hooks/useApprove";
import { chainToHandler, getTokenNameFromAddress } from "../../config";

interface Props {
  open: boolean;
  onClose: any;
  listing: OTCListing;
  refetch: any;
  setSelectedListing: any;
}

export function ListingModal({
  open,
  onClose,
  listing,
  refetch,
  setSelectedListing,
}: Props) {
  const [tokenApprovalState, tokenApprove, isLoadingTokenApproval] =
    useApproveToken(
      listing.tokenToReceive.contractAddress as string,
      listing.tokenToReceive.value,
      chainToHandler[listing.dstChain]["ERC20"]
    );
  const [nftApprovalState, nftApprove, isLoadingNFTApproval] =
    useApproveNFTCollection(
      listing.tokenToReceive.contractAddress as string,
      chainToHandler[listing.dstChain]["ERC721"]
    );

  const token = getTokenNameFromAddress(listing.tokenToReceive.contractAddress);
  let approvalState;
  let approveFn;
  let isLoadingApproval;
  if (token?.isNft) {
    approvalState = nftApprovalState;
    approveFn = nftApprove;
    isLoadingApproval = isLoadingNFTApproval;
  } else {
    approvalState = tokenApprovalState;
    approveFn = tokenApprove;
    isLoadingApproval = isLoadingTokenApproval;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <ListingCard
        setSelectedListing={setSelectedListing}
        listing={listing}
        refetch={refetch}
        approveInfo={[approvalState, approveFn, isLoadingApproval]}
        context="Dialog"
      />
    </Dialog>
  );
}
