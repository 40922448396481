import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from "react";
import {
    Box,
    Avatar,
    Typography,
    TextField,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Paper,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { Send, Photo, Info, PersonAdd } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { axiosGet, axiosPost } from "./shared/requests";
import { useWallet } from "./context/WalletContext";
import { formatDistanceToNow, parseISO } from "date-fns";
import { io } from "socket.io-client";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "./shared/firebase";
import { useLoader } from "./context/LoaderContext";
import { ChatRoom, ChatType, Participant } from "./shared/ChatRoom";
import ParticipantsDialog from "./components/ParticipantsDialog";
import useNotification from "./utilities/notificationUtils";
import { useNavigate } from "react-router-dom";

const socket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
    transports: ["websocket", "polling"],
    withCredentials: true
});

const HeaderContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    backgroundColor: "#f5f5f5",  // Light gray background
    borderBottom: "1px solid #e0e0e0", // Light divider line
    color: "#333333", // Dark text color
});

const ChatContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#f5f5f5",  // Light gray background
    color: "#333333", // Dark text color
});

const MessagesContainer = styled(List)({
    flex: 1,
    overflowY: "auto",
    padding: "20px",
    display: "flex",
    flexDirection: "column-reverse",
    backgroundColor: "white", // Background for messages area
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#b0b0b0", // Light gray for scrollbar thumb
        borderRadius: "3px",
    },
});

const MessageInputContainer = styled(Paper)({
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#ffffff", // White background for input area
    borderTop: "1px solid #e0e0e0", // Light divider line
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    color: "#333333", // Dark text color
});

interface Message {
    id: number;
    chatId: number;
    senderId: number;
    senderAddress: string;
    content: string | null;
    imageUrl: string | null;
    fromChain: string;
    createdAt: string;
    messageStatus: {
        id: number;
        seenAt: string | null;
    }[];
    sender: {
        id: number;
        name: string;
        address: string;
    }
}

const ChatArea: React.FC = () => {
    const { account } = useWallet();
    const { roomId } = useParams();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<Participant | null>();
    const [chatRoom, setChatRoom] = useState<ChatRoom | null>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [messageText, setMessageText] = useState<string>("");
    const [skip, setSkip] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const messagesContainerRef = useRef<HTMLUListElement>(null);
    const { showLoader, hideLoader } = useLoader();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [addUserDialog, setAddUserDialog] = useState(false);
    const [walletAddress, setWalletAddress] = useState<string>("");
    const { showError } = useNotification();
    const handleOpenDialog = () => setDialogOpen(true);
    const handleCloseDialog = () => setDialogOpen(false);

    useEffect(() => {
        if (roomId) {
            setMessages([]);
            setSkip(0);
            loadMessages();
        }
    }, [roomId]);

    useEffect(() => {
        if (roomId && account) {
            loadChatRoom();
            socket.emit("joinRoom", roomId);
            const handleNewMessage = (newMessage: any) => {
                if (newMessage?.sender?.address !== account &&
                    !messages.find(x => x.id === newMessage.id)) {

                    const message = {
                        ...newMessage,
                        senderAddress: newMessage?.sender?.address,
                    };

                    const event = socket.emit("messageSeen", message?.id, account);
                    console.log({ event });

                    setMessages(prevMessages => [message, ...prevMessages]);
                }
            };
            socket.on("newMessage", handleNewMessage);
            return () => {
                socket.emit("leaveRoom", roomId);
                socket.off("newMessage", handleNewMessage);
            };
        }
    }, [roomId, account]);

    useEffect(() => {
        if (messages.length > 0) {
            messages?.filter(x => x.messageStatus && x.messageStatus?.length > 0 && x.messageStatus[0]?.seenAt == null)?.forEach(x => socket.emit("messageSeen", x?.id, account))
        }
    }, [messages])

    const loadMessages = async () => {
        if (loading) return;

        setLoading(true);
        const newMessages: Message[] = await fetchMessages(skip);

        if (newMessages.length > 0) {
            setMessages((prevMessages) => {
                const combinedMessages = [...prevMessages, ...newMessages,]; // Prepend older messages
                return Array.from(new Map(combinedMessages.map((msg) => [msg.id, msg])).values());
            });
            setSkip((prevSkip) => prevSkip + newMessages.length);
        }
        setLoading(false);
    };

    const loadChatRoom = async () => {
        showLoader();
        try {
            const response = await axiosGet(`api/chats/chat-rooms/${roomId}`, account);
            const x = response?.chatRoom;
            const user = x?.participants?.find((y: any) => y?.address !== account);
            setChatRoom({
                ...x,
                subtitle: x?.subTitle || 'No message',
                name: x?.chatType === ChatType.PRIVATE ? (user?.name || user?.address) : (x?.name ?? ""),
                avatar: user ? user?.avatar : ''
            });
            const currentUser = x?.participants?.find((y: any) => y?.address === account);
            setCurrentUser(currentUser);
        } catch (error) {
            console.log("loadChatRoom", error);
        }
        hideLoader();
    };

    const fetchMessages = async (skip: number = 0, limit: number = 20) => {
        try {
            const response = await axiosGet(`api/chats/messages?chatId=${Number(roomId)}&skip=${skip}&take=${limit}`, account);
            if (response.messages?.length > 0) {
                return response?.messages?.map((x: any) => ({
                    ...x,
                    senderAddress: x?.sender?.address
                }));
            }
            return [];
        } catch (error) {
            console.error("Error fetching messages:", error);
            return [];
        }
    };

    const handleSendMessage = async () => {
        console.log(currentUser);

        if (messageText.trim()) {
            const newMessage: Message = {
                id: messages.length + 1,
                chatId: Number(roomId),
                senderId: 0,
                senderAddress: account || "",
                content: messageText,
                imageUrl: null,
                fromChain: "SANKO",
                createdAt: new Date().toISOString(),
                messageStatus: [],
                sender: {
                    id: currentUser?.id || 0,
                    name: currentUser?.name || "",
                    address: currentUser?.address || "",
                }
            };
            setMessages([newMessage, ...messages]);
            setMessageText("");
            await axiosPost('api/chats/message', {
                chatId: Number(roomId),
                content: messageText,
                chain: "SANKO"
            }, account);
        }
    };

    const handleUpload = async (file: File | null) => {
        if (!file) return "";

        const extension = file.name.split('.').pop();
        const uniqueName = `${Date.now()}.${extension}`;

        const storageRef = ref(storage, `${uniqueName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    console.error("Upload failed:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const url = await getDownloadURL(uploadTask.snapshot.ref);
                        const publicUrl = `https://firebasestorage.googleapis.com/v0/b/${storage.app.options.storageBucket}/o/${uniqueName}?alt=media`;
                        console.log("File available at", publicUrl);
                        resolve(publicUrl);
                    } catch (error) {
                        console.error("Error getting download URL:", error);
                        reject(error);
                    }
                }
            );
        });
    };

    const handleUploadPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            try {
                showLoader();
                const url = await handleUpload(event.target.files[0]);

                const newMessage: Message = {
                    id: messages.length + 1,
                    chatId: Number(roomId),
                    senderId: 0,
                    senderAddress: account || "",
                    content: null,
                    imageUrl: url as string,
                    fromChain: "SANKO",
                    createdAt: new Date().toISOString(),
                    messageStatus: [],
                    sender: {
                        id: currentUser?.id || 0,
                        name: currentUser?.name || "",
                        address: currentUser?.address || "",
                    }
                };
                setMessages([newMessage, ...messages]);

                await axiosPost('api/chats/message', {
                    chatId: Number(roomId),
                    imageUrl: url,
                    chain: "SANKO"
                }, account);

            } catch (error) {
            } finally {
                hideLoader();
            }

        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
        const element = event.currentTarget;
        const scrollTop = Math.floor(element.scrollTop); // Round scrollTop to avoid fractional values
        const isAtBottom = Math.abs(element.scrollHeight + scrollTop) === element.clientHeight;
        console.log(isAtBottom, element.scrollHeight, scrollTop, element.clientHeight);

        if (isAtBottom && !loading) {
            loadMessages();
        }
    };

    const formatTime = (timestamp: string) => {
        return formatDistanceToNow(parseISO(timestamp), { addSuffix: true });
    };

    const handleAddWallet = async () => {
        console.log(`Wallet Address Added: ${walletAddress}`);
        try {
            showLoader();
            const response = await axiosPost(`api/chats/chat-room/${roomId}/add`,
                {
                    walletAddress: walletAddress
                }
                , account);
            const x = response?.chatRoom;
            const user = x?.participants?.find((y: any) => y?.address !== account);
            setChatRoom({
                ...x,
                subtitle: x?.subTitle || 'No message',
                name: user ? (user?.name || user?.address) : (x?.name ?? ""),
                avatar: user ? user?.avatar : ''
            });
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
            setWalletAddress("");
            setAddUserDialog(false);
        }
    };

    const handleAddMemberClick = () => {
        setAddUserDialog(true);
    };

    const onRemoveParticipant = async (participant: Participant) => {
        console.log(`Wallet Address Added: ${walletAddress}`);
        try {
            showLoader();
            const response = await axiosPost(`api/chats/chat-room/${roomId}/remove`,
                {
                    userId: participant?.id
                }
                , account);
            const x = response?.chatRoom;
            const user = x?.participants?.find((y: any) => y?.address !== account);
            setChatRoom({
                ...x,
                subtitle: x?.subTitle || 'No message',
                name: user ? (user?.name || user?.address) : (x?.name ?? ""),
                avatar: user ? user?.avatar : ''
            });
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
            setWalletAddress("");
            setAddUserDialog(false);
        }
    };

    return (
        <ChatContainer>
            <HeaderContainer>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button color="secondary" onClick={() => navigate(-1)} sx={{ marginRight: '5px' }}>Back</Button>
                    <Typography variant="h6">{chatRoom?.name}</Typography>
                </Box>
                {chatRoom?.chatType === ChatType.GROUP && chatRoom?.creator?.address === account && <Box>
                    <IconButton color="secondary" onClick={handleOpenDialog}>
                        <Info />
                    </IconButton>
                    <IconButton color="secondary" onClick={handleAddMemberClick}>
                        <PersonAdd />
                    </IconButton>
                </Box>}
            </HeaderContainer>
            <MessagesContainer ref={messagesContainerRef} onScroll={handleScroll}>
                {messages.map((message) => (
                    <ListItem
                        key={message.id}
                        alignItems="flex-start"
                        sx={{
                            alignSelf: message?.senderAddress === account ? "flex-end" : "flex-start",
                            backgroundColor: "#333",
                            borderRadius: "12px",
                            padding: "8px",
                            marginBottom: "10px",
                            maxWidth: message?.senderAddress === account ? "60%" : "70%",
                            marginLeft: message?.senderAddress === account ? "auto" : "0",
                            marginRight: message?.senderAddress === account ? "0" : "auto",
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>{message?.sender?.name?.length > 0 ? message.sender.name?.charAt(0) : message.senderAddress?.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography sx={{ color: "#FFFFFF" }}>
                                    {message?.sender?.name?.length > 0 ? message.sender.name : message.senderAddress}
                                </Typography>
                            }
                            secondary={
                                <>
                                    {message.content ? (
                                        <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                                            {message.content}
                                        </Typography>
                                    ) : (
                                        <a target="_blank" rel="noopener noreferrer" href={message?.imageUrl || ""}>
                                            <img
                                                src={message.imageUrl || ""}
                                                alt="Uploaded"
                                                style={{ maxWidth: "200px", borderRadius: "8px" }}
                                            />
                                        </a>
                                    )}
                                    <Typography variant="caption" sx={{ display: "block", marginTop: "4px", color: "text.secondary" }}>
                                        {formatTime(message.createdAt)}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </MessagesContainer>

            <MessageInputContainer>
                <IconButton color="inherit" onClick={() => document.getElementById("fileInput")?.click()}>
                    <Photo />
                </IconButton>
                <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleUploadPhoto}
                />
                <TextField
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message"
                    sx={{
                        borderRadius: "20px",
                        marginRight: "10px",
                    }}
                />
                <IconButton color="inherit" onClick={handleSendMessage}>
                    <Send />
                </IconButton>
            </MessageInputContainer>
            <ParticipantsDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                participants={chatRoom?.participants ?? []}
                onRemoveParticipant={onRemoveParticipant}
            />
            <Dialog open={addUserDialog} onClose={() => setAddUserDialog(false)}>
                <DialogTitle>Add Wallet Address</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Wallet Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddUserDialog(false)}>Cancel</Button>
                    <Button onClick={() => handleAddWallet()} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </ChatContainer>
    );
};

export default ChatArea;
