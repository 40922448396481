import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { formatAddress } from "../formatAddress";
import { NFTAmountSelector } from "./nftSelector";
import { TokenAmountSelector } from "./tokenAmountSelector";
import { TokenBalance } from "./tokenBalance";
import { useNftInfo } from "../../hooks/useNFTBalances";
import { RenderExtraInfo } from "../../components/extraInfoComponents";

interface Props {
  amount: any;
  handleAmountChanged: any;
  token: any;
  tokenAddresses: any[];
  handleTokenChange: any;
  setApproveData?: any;
  tokenSpender?: string;
  label: string;
  context?: string;
  placeholder: string;
}

export default function TokenSelect({
  token,
  handleTokenChange,
  tokenAddresses,
  label,
  handleAmountChanged,
  amount,
  context = "from",
  placeholder,
}: Props) {
  const { data: nftInfo } = useNftInfo(token, amount, context === "to");
  return (
    <>
      <Grid item xs={12} md={token ? 6 : 12}>
        <Autocomplete
          options={tokenAddresses}
          getOptionLabel={(option) =>
            `${option.name} (${option.symbol}) ${formatAddress(option.address)}`
          }
          value={token}
          onChange={handleTokenChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.logoURI ? (
                <img
                  src={option.logoURI}
                  alt={option.symbol}
                  style={{ width: 30, height: 30, marginRight: 8 }}
                />
              ) : (
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    backgroundColor: "#ccc", // You can change this to a color you prefer
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                    fontSize: 14,
                    color: "#fff",
                    fontWeight: "bold",
                  }}>
                  {option.symbol.substring(0, 3)}
                </div>
              )}
              {option.name} ({option.symbol}) {formatAddress(option.address)}
            </li>
          )}
        />
      </Grid>

      {token && (
        <Grid item xs={12} md={6}>
          {token?.isNft &&
          (context === "from" || (context === "to" && token?.hiddenIds)) ? (
            <NFTAmountSelector
              context={context}
              placeholder={placeholder}
              key={token.address}
              id={amount}
              handleIdChange={handleAmountChanged}
              token={token}
            />
          ) : (
            <TokenAmountSelector
              placeholder={placeholder}
              key={token.address}
              amount={amount}
              handleAmountChanged={handleAmountChanged}
            />
          )}
        </Grid>
      )}
      <Grid item md={6}></Grid>
      <Grid item xs={12} md={6}>
        {token?.isNft && nftInfo && context === "to" && !token?.hiddenIds && (
          <Box className="info-item" p={1}>
            <RenderExtraInfo address={token.address} extraInfo={nftInfo} />
          </Box>
        )}
      </Grid>
      {context === "from" && token && !token?.isNft && (
        <TokenBalance
          token={token}
          handleTokenAmountChanged={handleAmountChanged}
        />
      )}
    </>
  );
}
