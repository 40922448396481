// utils/handleMetaMaskError.ts
export const handleMetaMaskError = (error: any) => {
    if (error?.info?.error?.code === 4001) {
        // User rejected the request
        return "User rejected the request. Please authorize the transaction.";
    } else if (error?.info?.error?.code === -32002) {
        // A request is pending (usually happens when the user has already a pending request)
        return "A request is pending. Please confirm the previous request.";
    } else if (error?.info?.error?.code === -32603) {
        // Internal JSON-RPC error (e.g., insufficient balance)
        if (error?.info?.error?.message.includes('insufficient funds')) {
            return "Insufficient funds. Please ensure you have enough balance.";
        }
        return "An internal error occurred. Please try again.";
    } else if (error?.info?.error?.message?.includes('user denied transaction signature')) {
        // User denied transaction signature
        return "Transaction denied. Please authorize the transaction.";
    } else if (error?.info?.error?.message?.includes('missing provider')) {
        // No provider detected
        return "Ethereum provider not found. Please install MetaMask.";
    } else {
        // General error message
        if (error?.info?.error?.message) {
            return error?.info?.error?.message;
        } else if (error?.message) {
            if (error?.message?.includes("invalid EIP-1193 provider")) {
                return "Ethereum provider not found, Please install metamask and try again."
            } else if (error?.message?.includes("could not decode result data")) {
                return "Change your metamask network to Sanko chain or Arbitrum"
            } else {
                return error?.message;
            }
        } else {
            return "An unknown error occurred.";
        }
    }
};
