import { Chain } from "../types";

const chains: Chain[] = [
  // {
  //   name: "Sanko Testnet",
  //   id: 1,
  //   chainId: 1992,
  //   address: "0xD70AF668847d300b6e41196aCBc803d26c3baeD6",
  //   rpc: "https://sanko-arb-sepolia.rpc.caldera.xyz/http",
  //   logoURI: "/sanko.png",
  // },
  // {
  //   name: "Arbitrum Sepolia",
  //   id: 2,
  //   chainId: 421614,
  //   address: "0xD70AF668847d300b6e41196aCBc803d26c3baeD6",
  //   rpc: "https://arbitrum-sepolia.blockpi.network/v1/rpc/public",
  //   logoURI: "/sanko.png",
  // },
  {
    name: "Sanko",
    id: 3,
    chainId: 1996,
    address: "0xD70AF668847d300b6e41196aCBc803d26c3baeD6",
    rpc: "https://mainnet.sanko.xyz",
    logoURI: "/sanko.png",
  },
  {
    name: "Arbitrum",
    id: 4,
    chainId: 42161,
    address: "0x312601e7cCf1643F88E887f992C5d045DB214fC6",
    rpc: "https://arb1.arbitrum.io/rpc",
    logoURI: "/arbitrum.png",
  },
];

enum ChainEnum {
  Sanko = 1996,
  Arbitrum = 42161,
  SankoTestnet = 1992,
  ArbitrumTestnet = 421614,
}

const getChain = (chainId: number): Chain | undefined => {
  return chains.find((x) => x.chainId === chainId);
};

export { chains, getChain, ChainEnum };
