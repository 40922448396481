import * as ethers from "ethers";
import {
  chains,
  nftContractConfig,
  swapContractConfig,
  tokenContractConfig,
} from "../config";
import { useMemo } from "react";

const getTokenSmartContract = async (tokenAddress: string) => {
  try {
    const ethersProvider = new ethers.BrowserProvider(window.ethereum);
    const signer = await ethersProvider.provider.getSigner();
    const tokenContract = new ethers.Contract(
      tokenAddress,
      tokenContractConfig.abi,
      signer
    );
    return tokenContract;
  } catch (e) {
    throw e;
  }
};

const getNFTSmartContract = async (tokenAddress: string) => {
  try {
    const ethersProvider = new ethers.BrowserProvider(window.ethereum);
    const signer = await ethersProvider.provider.getSigner();
    const tokenContract = new ethers.Contract(
      tokenAddress,
      nftContractConfig.abi,
      signer
    );
    return tokenContract;
  } catch (e) {
    throw e;
  }
};

const useNFTSmartContract = (tokenAddress?: string) => {
  return useMemo(
    () => (tokenAddress ? getNFTSmartContract(tokenAddress) : undefined),
    [tokenAddress]
  );
};

const useTokenSmartContract = (tokenAddress?: string) => {
  return useMemo(
    () => (tokenAddress ? getTokenSmartContract(tokenAddress) : undefined),
    [tokenAddress]
  );
};

const getSwapSmartContract = async (network: number) => {
  try {
    const ethersProvider = new ethers.BrowserProvider(window.ethereum);
    const signer = await ethersProvider.provider.getSigner();
    const tokenContract = new ethers.Contract(
      chains.find((x) => x.chainId === network)?.address ?? "",
      swapContractConfig.abi,
      signer
    );
    return tokenContract;
  } catch (e) {
    throw e;
  }
};

const useSwapSmartContract = (network: number) => {
  return useMemo(() => getSwapSmartContract(network), [network]);
};

const getSwapSmartContractWithoutSigner = async (network: number) => {
  try {
    const chain = chains.find((x) => x.chainId === network);
    const ethersProvider = new ethers.BrowserProvider(window.ethereum);
    const tokenContract = new ethers.Contract(
      chain?.address ?? "",
      swapContractConfig.abi,
      ethersProvider.provider
    );
    return tokenContract;
  } catch (e) {
    throw e;
  }
};

export {
  getTokenSmartContract,
  getSwapSmartContract,
  getSwapSmartContractWithoutSigner,
  useTokenSmartContract,
  useSwapSmartContract,
  getNFTSmartContract,
  useNFTSmartContract,
};
