import { Box } from "@mui/material";
import { NftAttributes } from "./nftAttributes";

export default function GenericPosition({ extraInfo }: any) {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {extraInfo?.image && (
        <img src={extraInfo.image} width={100} height={100} alt="s" />
      )}
      {Object.keys(extraInfo).length > 0 && (
        <NftAttributes attributes={extraInfo} />
      )}
    </Box>
  );
}
