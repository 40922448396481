import { TokenAddress } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { useActiveWeb3React } from "./useActiveWeb3React";
import { chainToConfig, nftContractConfig } from "../config";
import { ZeroAddress } from "ethers";
import { readContract } from "@wagmi/core";

export function useNftExists(token: TokenAddress | null, index?: bigint) {
  const { account } = useActiveWeb3React();

  return useQuery({
    queryKey: [account, token, Number(index)],
    queryFn: async () => {
      try {
        if (!account || !token || !token.isNft || typeof index !== "bigint")
          return false;

        const chainConfig = chainToConfig[token.chainId];
        const owner = await await readContract(chainConfig, {
          abi: nftContractConfig.abi,
          args: [index],
          address: token.address as any,
          functionName: "ownerOf",
          account: account,
        });
        return owner !== ZeroAddress;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  });
}
