import { ChainEnum, chainToConfig, nftContractConfig } from "../../config";
import { serializeNFTAttributes } from "../../utilities/serialize";
import { multicall } from "@wagmi/core";

export const getBunsMetadata = async (
  id: number[],
  _: string
): Promise<any[]> => {
  const chainConfig = chainToConfig[ChainEnum.Sanko];
  const calls = id.map((val) => ({
    functionName: "tokenURI",
    address: "0xE3351CE33689dc444B1a45B8f8F447A181D57227" as any,
    abi: nftContractConfig.abi as any,
    args: [val],
  }));
  const results = await multicall(chainConfig, {
    contracts: calls,
  });
  return results.map((data, index) => {
    if (data.status === "success") {
      //@ts-ignore
      const decodedResult = JSON.parse(
        //@ts-ignore
        atob(data.result.split(",")[1])
      );
      //@ts-ignore
      const { attributes, image } = decodedResult;
      return {
        tokenId: Number(id[index]),
        ...serializeNFTAttributes(attributes),
        image,
      };
    }
    return undefined;
  });
};
