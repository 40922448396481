import Group from "@mui/icons-material/Group";
import Message from "@mui/icons-material/Message";
import ProfileIcon from "@mui/icons-material/Settings";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    styled,
    TextField,
    Typography
} from "@mui/material";
import Badge from '@mui/material/Badge';
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "./context/LoaderContext";
import { useWallet } from "./context/WalletContext";

import { axiosGet, axiosPost } from "./shared/requests";
import useNotification from "./utilities/notificationUtils";
import { ChatRoom, ChatType } from "./shared/ChatRoom";

const StyledPaper = styled(Paper)(({ theme }) => ({
    height: "100vh",
    borderRadius: "16px",
    overflow: "hidden",
    position: "relative",
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    padding: "20px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between", // Ensures the button and search bar are spaced apart
    alignItems: "center", // Aligns the items vertically
}));

const StyledList = styled(List)(({ theme }) => ({
    height: "calc(100vh - 100px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        borderRadius: "3px",
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: "15px 20px",
    cursor: "pointer",
}));

const ChatMessenger: React.FC = () => {
    const { account } = useWallet();
    const navigate = useNavigate();
    const { showError } = useNotification();
    const { showLoader, hideLoader } = useLoader();
    const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);
    const [filteredRooms, setFilteredRooms] = useState<ChatRoom[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [groupDialog, setGroupDialog] = useState<boolean>(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
    const [walletAddress, setWalletAddress] = useState<string>("");
    const [groupName, setGroupName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");

    useEffect(() => {
        const fetchChatRooms = async (shouldShowLoader = true) => {
            try {
                if (shouldShowLoader) showLoader();
                const response = await axiosGet("api/chats/chat-rooms", account);

                const chatRooms = response?.chatRooms?.map((x: any) => {
                    const user = x?.participants?.find((y: any) => y?.address !== account);
                    return ({
                        ...x,
                        subtitle: x?.subTitle || 'No message',
                        name: x?.chatType === ChatType.PRIVATE ? (user?.name || user?.address) : (x?.name ?? ""),
                        avatar: user ? user?.avatar : ''
                    });
                });

                console.log(response?.chatRooms);


                setChatRooms(chatRooms);
                setFilteredRooms(chatRooms);
            } catch (err) {
                setError("Failed to load chat rooms. Please try again later.");
            } finally {
                if (shouldShowLoader) hideLoader();
            }
        };

        const fetchUserDetails = async () => {
            try {
                showLoader();
                const response = await axiosGet(`api/users/my`, account);
                if (response?.user?.name) {
                    setUserName(response?.user?.name || "");
                }
            } catch (err) {
                setError("Failed to load user details. Please try again later.");
            } finally {
                hideLoader();
            }
        };

        if (account) {
            fetchChatRooms();
            fetchUserDetails();

            const intervalId = setInterval(() => {
                fetchChatRooms(false);
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [account]);

    useEffect(() => {
        console.log("chatRooms filtered", chatRooms);

        const filtered = chatRooms.filter(
            (room) =>
                room.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                room.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredRooms(filtered);
    }, [searchQuery, chatRooms]);

    const handleRoomClick = (roomId: number) => {
        console.log(`Selected room: ${roomId}`);
        navigate(`/chat-area/${roomId}`);
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleAddClick = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleWalletAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWalletAddress(e.target.value);
    };

    const handleAddWallet = async (group: boolean = false) => {
        console.log(`Wallet Address Added: ${walletAddress}`);
        try {
            showLoader();
            const response = await axiosPost("api/chats/chat-room",
                group ? {
                    roomName: groupName,
                    chain: "SANKO"
                } : {
                    recipientUserAddress: walletAddress
                }
                , account);
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
            setWalletAddress("");
            handleDialogClose();
            setGroupDialog(false);
        }
    };

    const handleUpdateUser = async () => {
        try {
            showLoader();
            const response = await axiosPost(`api/users/update`, {
                name: userName.toUpperCase()
            }, account);
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
            setSettingsDialogOpen(false);
        }

    };

    if (error) {
        return (
            <Box p={3}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <StyledPaper elevation={3}>
            <SearchContainer>
                <Button color="secondary" onClick={() => navigate(-1)} sx={{ marginRight: '5px' }}>Back</Button>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search chat rooms..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                        },
                    }}
                />
            </SearchContainer>

            <StyledList>
                {filteredRooms.map((room) => (
                    <StyledListItem
                        key={room.id}
                        onClick={() => handleRoomClick(room.id)}
                        tabIndex={0}
                        role="button"
                        aria-label={`Chat room ${room.name}: ${room.subtitle}`}
                    >
                        <ListItemAvatar>
                            <Badge
                                badgeContent={room.unreadCount > 0 ? room.unreadCount : null}
                                color="primary"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Avatar
                                    src={`https://${room.avatar}`}
                                    alt={room.name}
                                    sx={{ width: 50, height: 50, boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
                                />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    {room.name}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="body2" sx={{ color: "text.secondary", mt: 0.5 }}>
                                    {room.subtitle}
                                </Typography>
                            }
                            sx={{ ml: 2 }}
                        />
                    </StyledListItem>
                ))}
            </StyledList>

            <Fab
                onClick={() => setSettingsDialogOpen(true)}
                sx={{
                    position: 'fixed',
                    bottom: 210,
                    right: 25,
                    zIndex: 1000,
                }}

            >
                <ProfileIcon />
            </Fab>

            <Fab
                onClick={() => setGroupDialog(true)}
                sx={{
                    position: 'fixed',
                    bottom: 140,
                    right: 25,
                    zIndex: 1000,
                }}

            >
                <Group />
            </Fab>

            <Fab
                onClick={handleAddClick}
                sx={{
                    position: 'fixed',
                    bottom: 70,
                    right: 25,
                    zIndex: 1000,
                }}

            >
                <Message />
            </Fab>

            <Dialog open={groupDialog} onClose={() => setGroupDialog(false)}>
                <DialogTitle>Group name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setGroupDialog(false)}>Cancel</Button>
                    <Button onClick={() => handleAddWallet(true)} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add Wallet Address</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Wallet Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={walletAddress}
                        onChange={handleWalletAddressChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={() => handleAddWallet()} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)}>
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSettingsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleUpdateUser} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledPaper>
    );
};

export default ChatMessenger;
