import { create } from "zustand";

type Market = {
  address: any;
  primePool: any;
  token0: any;
  token1: any;
  callToken: any;
  putToken: any;
  inversePrice: boolean;
  pricing: any;
  pools: any[];
  feeStrategy: any;
  ticker: string;
};

type Slot0 = {
  sqrtPriceX96: bigint;
  tick: number;
};

type Store = {
  data: Market | null;
  slot0: Slot0;
};

type Actions = {
  set: (market: Market) => void;
  updateSlot0: (slot0: Slot0) => void;
};

export const useMarketStore = create<Store & Actions>()((set) => ({
  slot0: {
    sqrtPriceX96: BigInt(0),
    tick: 0,
  },
  data: null,
  set(market) {
    set((prev) => ({
      ...prev,
      data: market,
    }));
  },
  updateSlot0(slot0) {
    set((prev) => ({ ...prev, slot0 }));
  },
}));
