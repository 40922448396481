"use client";
import "@rainbow-me/rainbowkit/styles.css";

import {
  darkTheme,
  getDefaultConfig,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { http, WagmiProvider } from "wagmi";
import { arbitrum, sanko } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { PropsWithChildren } from "react";

const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "f27b1427d705695c6b94871403214d9d",
  chains: [
    arbitrum,
    {
      ...sanko,
      contracts: {
        multicall3: {
          address: "0xB32d192e07727A0F6f48e721791aa4e02cd292e1",
        },
      },
    },
  ],
  transports: {
    [arbitrum.id]: http(process.env.REACT_APP_ARBITRUM_URL),
    [sanko.id]: http(process.env.REACT_APP_SANKO_URL),
  },
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const Providers = ({ children }: PropsWithChildren) => {
  const queryClient = new QueryClient();
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Providers;
