import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { connectWallet as connectMetamask } from '../shared';
import useNotification from '../utilities/notificationUtils';
import { handleMetaMaskError } from '../utilities/handleMetaMaskError';
import * as ethers from "ethers";
import { chains } from '../config';

interface WalletContextType {
    isConnected: boolean;
    account: string | null;
    chainId: number | null;
    network: number;
    connectWallet: () => Promise<void>;
    disconnectWallet: () => void;
    changeNetwork: (network: number) => void;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [chainId, setChainId] = useState<number | null>(null);
    const [account, setAccount] = useState<string | null>(null);
    const [network, setNetwork] = useState<number>(chains[0].chainId);
    const { showError, showInfo } = useNotification();

    useEffect(() => {
        const initializeConnection = async () => {
            try {
                if (window?.ethereum) {
                    // Check if already connected
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    const network = await new ethers.BrowserProvider(window.ethereum).provider.getNetwork();
                    const chainId = Number(network.chainId);

                    if (accounts.length > 0) {
                        setAccount(accounts[0]);
                        setChainId(chainId);
                        setNetwork(chainId);
                        setIsConnected(true);
                    }

                    // Setup event listeners
                    window.ethereum.on('accountsChanged', handleAccountsChanged);
                    window.ethereum.on('chainChanged', handleChainChanged);
                    window.ethereum.on('disconnect', handleDisconnect);

                    // Cleanup event listeners on component unmount
                    return () => {
                        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
                        window.ethereum.removeListener('chainChanged', handleChainChanged);
                        window.ethereum.removeListener('disconnect', handleDisconnect);
                    };
                }
            } catch (error: any) {
                showError(handleMetaMaskError(error));
            }
        };

        initializeConnection();

    }, []);

    const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
            setAccount(accounts[0]);
        } else {
            disconnectWallet();
        }
    };

    const handleChainChanged = (chainId: string) => {
        const newChainId = Number(chainId);
        setChainId(newChainId);
        setNetwork(newChainId);
        const chain = chains.find(x => x.chainId === Number(chainId));
        showInfo(`Network changed to ${chain?.name}`);
    };

    const handleDisconnect = () => {
        disconnectWallet();
    };

    const connectWallet = async () => {
        try {
            if (window?.ethereum) {
                const signer = await connectMetamask();
                const walletAddress = await signer.getAddress();
                const ethersProvider = new ethers.BrowserProvider(window.ethereum);
                const network = await ethersProvider.provider.getNetwork();
                const chainId = Number(network.chainId);
                setAccount(walletAddress);
                setChainId(chainId);
                setNetwork(chainId);
                setIsConnected(true);
            } else {
                window.open('https://metamask.io/download', '_blank');
            }
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        }
    };

    const disconnectWallet = () => {
        setAccount(null);
        setChainId(null);
        setIsConnected(false);
    };

    const changeNetwork = (network: number) => {
        if (!network && network != chainId) {
            showError("You cannot change network");
        } else {
            setNetwork(network);
        }
    }

    return (
        <WalletContext.Provider value={{ isConnected, account, connectWallet, disconnectWallet, chainId, network, changeNetwork }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error("useWallet must be used within a WalletProvider");
    }
    return context;
};
