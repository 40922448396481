import { create } from "zustand";

type Keys = "WETH" | "DMT";
type PriceStore = Record<string, number>;

type Store = {
  prices: PriceStore;
};

type Actions = {
  set: (key: Keys, price: number) => void;
};

const useTokenPricesStore = create<Store & Actions>()((set) => ({
  prices: {
    DMT: 0,
    WETH: 0,
  },
  set(key, price) {
    set((prev) => ({
      ...prev,
      prices: {
        ...prev.prices,
        [key]: price,
      },
    }));
  },
}));

export default useTokenPricesStore;
