import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useWallet } from "../../context/WalletContext";
import { formatAddress } from "../../shared";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useMarketStore } from "../../zustand/useMarketStore";
import useTokenPricesStore from "../../zustand/useTokenPricesStore";
import { useReadContract } from "wagmi";
import { ZeroAddress } from "ethers";
import { uniswapv3 } from "../../config";
import ChatButton from "../../components/ChatButton";
import { axiosGet } from "../../shared/requests";

interface MenuItem {
  text: string;
  href: string;
}

const connectButton = () => (
  <ConnectButton.Custom>
    {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      authenticationStatus,
      mounted,
    }) => {
      // Note: If your app doesn't use authentication, you
      // can remove all 'authenticationStatus' checks
      const ready = mounted && authenticationStatus !== "loading";
      const connected =
        ready &&
        account &&
        chain &&
        (!authenticationStatus || authenticationStatus === "authenticated");

      return (
        <div
          {...(!ready && {
            "aria-hidden": true,
            style: {
              opacity: 0,
              pointerEvents: "none",
              userSelect: "none",
            },
          })}>
          {(() => {
            if (!connected) {
              return (
                <Button onClick={openConnectModal} variant="contained">
                  Connect Wallet
                </Button>
              );
            }

            if (chain.unsupported) {
              return (
                <Button onClick={openChainModal} variant="contained">
                  Wrong network
                </Button>
              );
            }

            return (
              <div style={{ display: "flex", gap: 12, flexWrap: "wrap" }}>
                <Button
                  onClick={openChainModal}
                  style={{ display: "flex", alignItems: "center" }}
                  variant="contained">
                  {chain.hasIcon && (
                    <div
                      style={{
                        background: chain.iconBackground,
                        width: 12,
                        height: 12,
                        borderRadius: 999,
                        overflow: "hidden",
                        marginRight: 4,
                      }}>
                      {chain.iconUrl && (
                        <img
                          alt={chain.name ?? "Chain icon"}
                          src={chain.iconUrl}
                          style={{ width: 12, height: 12 }}
                        />
                      )}
                    </div>
                  )}
                  {chain.name}
                </Button>

                <Button onClick={openAccountModal} variant="contained">
                  {account.displayName}
                  {account.displayBalance ? ` (${account.displayBalance})` : ""}
                </Button>
              </div>
            );
          })()}
        </div>
      );
    }}
  </ConnectButton.Custom>
);

export const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isConnected, connectWallet, account } = useWallet();
  const [unreadCount, setUnreadCount] = useState(0);
  const { data: currentMarket, set, updateSlot0 } = useMarketStore();
  const { set: setPrice } = useTokenPricesStore();
  const { data: slot0 }: any = useReadContract({
    abi: uniswapv3.abi,
    functionName: "slot0",
    address: currentMarket ? currentMarket.primePool.address : ZeroAddress,
  });

  useEffect(() => {
    if (!currentMarket || !slot0) return;
    updateSlot0({
      sqrtPriceX96: slot0[0],
      tick: slot0[1],
    });
  }, [currentMarket, slot0, updateSlot0]);

  useEffect(() => {
    if (!currentMarket) {
      set({
        address: "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B",
        callToken: {
          address: "0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8",
          decimals: 18,
          symbol: "DMT",
        },
        putToken: {
          address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
          decimals: 18,
          symbol: "WETH",
        },
        feeStrategy: "0x0D2fCCDA638225dAb6E2c713760A8DB211B95Ad3",
        token0: {
          address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
          decimals: 18,
          symbol: "WETH",
        },
        token1: {
          address: "0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8",
          decimals: 18,
          symbol: "DMT",
        },
        inversePrice: true,
        pools: [
          {
            feeBps: 10000,
            identifier: "uniswap",
            tickSpacing: 200,
            address: "0xE03Da31Bc2AA7e45D37a7681Aa258F095E3e77E1",
            handler: "0xB09B7a74523E783b71b19534BD1C5f1f8733fF8e",
            name: "Uniswap V3",
          },
        ],
        ticker: "DMT-WETH",
        pricing: "0x5de5c21d4550121A66a927f5e8eEe6c8D007b77B",
        primePool: {
          feeBps: 10000,
          identifier: "uniswap",
          tickSpacing: 200,
          address: "0xE03Da31Bc2AA7e45D37a7681Aa258F095E3e77E1",
          handler: "0xB09B7a74523E783b71b19534BD1C5f1f8733fF8e",
          name: "Uniswap V3",
        },
      });
    }
  }, [currentMarket, set]);

  useEffect(() => {
    const DEFI_LAMMA_COINS_URL = "https://coins.llama.fi/prices/current/";
    const queryString =
      "arbitrum:0x82aF49447D8a07e3bd95BD0d56f35241523fBab1,arbitrum:0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8";

    fetch(`${DEFI_LAMMA_COINS_URL}${queryString}`)
      .then((res) => res.json())
      .then((price) => {
        const wethPrice = (price["coins"][
          "arbitrum:0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
        ]["price"] ?? 0) as number;

        setPrice("WETH", wethPrice);

        const dmtPrice = (price["coins"][
          "arbitrum:0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8"
        ]["price"] ?? 0) as number;

        setPrice("DMT", dmtPrice);
      });
  }, [setPrice]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const menuItems: MenuItem[] = [
    { text: "Marketplace", href: "/" },
    { text: "Create Listing", href: "/bridge" },
    { text: "Tutorial", href: "/tutorial-page" },
    { text: "Connect", href: "#" },
  ];

  const list = () => (
    <Box
      sx={{ width: 250, height: "100vh" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="drawer">
      <List>
        {menuItems.slice(0, -1).map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ fontFamily: "sans-serif, roboto" }}>
            <Button
              key={index}
              component={RouterLink}
              to={item.href}
              color="secondary"
              sx={{ mx: 1 }}
              className="nav-item">
              {item.text}
            </Button>
          </ListItem>
        ))}
        <ListItem disablePadding sx={{ fontFamily: "sans-serif, roboto" }}>
          {connectButton()}
        </ListItem>
        <hr className="hr mx-1 my-2" />
      </List>
    </Box>
  );

  useEffect(() => {
    if (account) {
      const fetchUnreadMessagesCount = async () => {
        try {
          const response = await axiosGet('api/chats/unread-messages-count', account);
          console.log("unread-messages-count", response);
          setUnreadCount(response.unreadCount);
        } catch (error) {
          console.error('Error fetching unread message count:', error);
        }
      };
      const intervalId = setInterval(fetchUnreadMessagesCount, 5000);
      fetchUnreadMessagesCount();

      return () => clearInterval(intervalId);
    }
  }, [account]);

  return (
    <>
      <div className="navbar-wrapper">
        <AppBar
          position="static"
          sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
          <Toolbar>
            <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}>
              {menuItems.slice(0, -1).map((item, index) => (
                <Button
                  key={index}
                  component={RouterLink}
                  to={item.href}
                  color="secondary"
                  sx={{ mx: 0, fontSize: "10px" }}>
                  {item.text}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {connectButton()}
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                width: "100%",
                justifyContent: "flex-end",
              }}>
              <IconButton
                edge="start"
                color="secondary"
                aria-label="menu"
                onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </div>
      <ChatButton unreadCount={unreadCount} />
    </>
  );
};
