import { chainToHandler } from "../config";
import { TokenAddress } from "../types";

type NFTAttributes = Record<string, string>;

export const serializeToken = (token: TokenAddress, tokenAmt: bigint) => ({
  contractAddress: token.address,
  handler: chainToHandler[token.chainId][!token.isNft ? "ERC20" : "ERC721"],
  value: tokenAmt,
});

export const serializeListing = (
  seller: string,
  tokenForSale: TokenAddress,
  tokenToReceive: TokenAddress,
  tokenForSaleAmt: bigint,
  tokenToReceiveAmt: bigint,
  endpoint: number
) => ({
  seller,
  tokenForSale: serializeToken(tokenForSale, tokenForSaleAmt),
  tokenToReceive: serializeToken(tokenToReceive, tokenToReceiveAmt),
  destinationEndpoint: BigInt(endpoint),
});

export const serializeNFTAttributes = (attributes: NFTAttributes[]) => {
  return attributes.reduce(
    (prev, curr) => ({ ...prev, [curr["trait_type"]]: curr["value"] }),
    {}
  );
};
