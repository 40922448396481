import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { OTCListing } from "../../types";
import { formatAddress } from "../../shared";
import { getChain, getTokenNameFromAddress } from "../../config";
import { formatUnits } from "viem";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import { useManageListing } from "../../hooks/useManageListing";
import { Close } from "@mui/icons-material";
import { useSwitchChain } from "wagmi";
import { ApprovalState } from "../../hooks/useApprove";
import { RenderExtraInfo } from "../../components/extraInfoComponents";
import { getAddress, MaxUint256 } from "ethers";
import TokenSelect from "../../shared/tokenSelect";

type ListingCardProps = {
  listing: OTCListing;
  refetch: any;
  context?: "Dialog" | "Card";
  setSelectedListing: any;
  approveInfo?: [ApprovalState, () => Promise<void>, boolean];
  positions?: any[];
};

export const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  refetch,
  context = "Card",
  setSelectedListing,
  approveInfo,
}) => {
  const { account, chainId } = useActiveWeb3React();

  const [collectionOfferNFTID, setCollectionOfferNFTID] = useState<
    number | undefined
  >();

  const fromToken = getTokenNameFromAddress(
    listing.tokenForSale.contractAddress
  );
  const toToken = getTokenNameFromAddress(
    listing.tokenToReceive.contractAddress
  );

  const fromChain = getChain(fromToken?.chainId ?? 0);
  const toChain = getChain(toToken?.chainId ?? 0);

  const [loading, setLoading] = useState(false);

  const { switchChain } = useSwitchChain();

  const isCollectionOffer =
    context === "Dialog" &&
    toToken?.isNft &&
    listing.tokenToReceive.value === MaxUint256;

  const { cancelListing, claimListing } = useManageListing(
    listing,
    setLoading,
    () => setSelectedListing(undefined),
    refetch,
    isCollectionOffer ? collectionOfferNFTID : undefined
  );

  const handleCollectionOfferNFTIDChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    setCollectionOfferNFTID(
      parseFloat(event.target.value) || parseFloat(value) || 0
    );
  };

  const disabled = loading || (isCollectionOffer && !collectionOfferNFTID);

  return (
    <Card className="listing-card" sx={{ boxShadow: 1, height: "100%" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-around",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mb={2}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            P2P Listing
          </Typography>
          {context === "Dialog" && (
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectedListing(undefined)}
            />
          )}
        </Box>
        <Typography
          variant="caption"
          component="div"
          sx={{ mb: 2, fontWeight: 600 }}>
          Exchanging
        </Typography>
        <div className="mb-2" style={{ display: "flex", alignItems: "center" }}>
          {fromToken?.logoURI ? (
            <img
              src={fromToken?.logoURI}
              alt={fromToken.symbol}
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
          ) : (
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                backgroundColor: "#ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 8,
                fontSize: 16,
                color: "#fff",
                fontWeight: "bold",
              }}>
              {fromToken?.symbol.substring(0, 3)}
            </div>
          )}
          <Typography variant="body2" component="div">
            {fromToken?.name} ({fromToken?.symbol}) <TrendingFlatIcon />{" "}
            {fromToken?.isNft
              ? listing.tokenForSale.value.toString()
              : formatUnits(
                  listing.tokenForSale.value,
                  fromToken?.decimals || 18
                )}
          </Typography>
        </div>
        {listing.extraBuyInfo && (
          <Box className="info-item" p={1}>
            <RenderExtraInfo
              address={getAddress(listing.tokenForSale.contractAddress)}
              extraInfo={listing.extraBuyInfo}
            />
          </Box>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {fromChain?.logoURI ? (
              <img
                src={fromChain?.logoURI}
                alt={fromChain.name}
                style={{ width: 24, height: 24, borderRadius: "50%" }}
              />
            ) : null}
            <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
              {formatAddress(fromToken?.address ?? "", 10)}
            </Typography>
          </div>
        </div>
        <Typography
          variant="caption"
          component="div"
          sx={{ mb: 2, fontWeight: 600 }}>
          Asking price
        </Typography>
        <div className="mb-2" style={{ display: "flex", alignItems: "center" }}>
          {toToken?.logoURI ? (
            <img
              src={toToken?.logoURI}
              alt={toToken.symbol}
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
          ) : (
            <div
              style={{
                width: 50,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "#ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 8,
                fontSize: 8,
                color: "#fff",
                fontWeight: "bold",
              }}>
              {toToken?.symbol.substring(0, 1)}
            </div>
          )}
          <Typography variant="body2" component="div">
            {toToken?.name} ({toToken?.symbol}) <TrendingFlatIcon />{" "}
            {toToken?.isNft
              ? listing.tokenToReceive.value === MaxUint256
                ? "Collection Offer"
                : listing.tokenToReceive.value.toString()
              : formatUnits(
                  listing.tokenToReceive.value,
                  toToken?.decimals || 18
                )}
          </Typography>
        </div>
        {listing.extraSellInfo && (
          <Box className="info-item" p={1}>
            <RenderExtraInfo
              address={getAddress(listing.tokenToReceive.contractAddress)}
              extraInfo={listing.extraSellInfo}
            />
          </Box>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {toChain?.logoURI ? (
              <img
                src={toChain?.logoURI}
                alt={toChain.name}
                style={{ width: 24, height: 24, borderRadius: "50%" }}
              />
            ) : null}
            <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
              {formatAddress(toToken?.address ?? "", 10)}
            </Typography>
          </div>
        </div>
        {isCollectionOffer && (
          <Grid xs={12} my={2}>
            <TokenSelect
              amount={collectionOfferNFTID}
              token={toToken}
              handleTokenChange={() => {}}
              tokenAddresses={[toToken]}
              label="Select NFT"
              handleAmountChanged={handleCollectionOfferNFTIDChanged}
              context="from"
              placeholder="Select NFT"
            />
          </Grid>
        )}
        <Box>
          {context === "Card" ? (
            listing.seller.toLowerCase() === account?.toLowerCase() ? (
              listing.srcChain !== chainId ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    switchChain({ chainId: listing.srcChain });
                  }}
                  sx={{ width: "100%" }}>
                  Switch Chain
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={cancelListing}
                  color="primary">
                  Close Listing
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                onClick={() => setSelectedListing(listing)}
                sx={{ width: "100%" }}
                color="primary">
                View
              </Button>
            )
          ) : listing.dstChain !== chainId ? (
            <Button
              variant="contained"
              onClick={() => {
                switchChain({ chainId: listing.dstChain });
              }}
              sx={{ width: "100%" }}>
              Switch Chain
            </Button>
          ) : approveInfo?.[0] === ApprovalState.NOT_APPROVED ? (
            <Button
              variant="contained"
              onClick={approveInfo?.[1]}
              sx={{ width: "100%" }}>
              Approve
            </Button>
          ) : (
            <Button
              disabled={disabled}
              variant="contained"
              onClick={claimListing}
              sx={{ width: "100%" }}>
              Accept Terms and Trade
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
