import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyA4bwV9chY2RjvkuWQLsYtEmJbQ1fndfEQ",
    authDomain: "sanko-predicts.firebaseapp.com",
    projectId: "sanko-predicts",
    storageBucket: "sanko-predicts.appspot.com",
    messagingSenderId: "117778824116",
    appId: "1:117778824116:web:239e3d6d9b95d90fba10e1",
    measurementId: "G-7DGEPVESMN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage
const storage = getStorage(app);

export { storage };
