import { Autocomplete, Box, TextField } from "@mui/material";
import { useMemo } from "react";
import { useOwnedNfts } from "../../hooks/useNFTBalances";
import { RenderExtraInfo } from "../../components/extraInfoComponents";
import { ZeroAddress } from "ethers";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";

interface Props {
  id: any;
  handleIdChange: any;
  token: any;
  setApproveData?: any;
  tokenSpender?: string;
  placeholder: string;
  context?: "from" | "to";
}

export function NFTAmountSelector({
  id,
  handleIdChange,
  token,
  placeholder,
  context,
}: Props) {
  const { account } = useActiveWeb3React();

  let { data: positions = [] } = useOwnedNfts(
    token.address,
    token.chainId,
    token?.hiddenIds && context === "to" ? ZeroAddress : account
  );

  positions = positions.filter((val) => typeof val !== "undefined");

  const tokenIds = useMemo(
    () => positions.map((val) => Number(val.tokenId)),
    [positions]
  );

  const optionMap: any = useMemo(() => {
    return positions.reduce(
      (prev, curr) => ({ ...prev, [curr.tokenId]: { ...curr } }),
      {}
    );
  }, [positions]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Autocomplete
        sx={{ width: "100%" }}
        options={tokenIds}
        getOptionLabel={(option) => option.toString()}
        value={id || null}
        onChange={handleIdChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            <RenderExtraInfo
              address={token.address}
              extraInfo={optionMap[option]}
            />
          </li>
        )}
      />
      {optionMap[id] && (
        <Box className="info-item" p={1}>
          <RenderExtraInfo address={token.address} extraInfo={optionMap[id]} />
        </Box>
      )}
    </Box>
  );
}
