import { Box, TextField } from "@mui/material";

interface Props {
  amount: any;
  handleAmountChanged: any;
  placeholder: string;
}

export function TokenAmountSelector({
  amount,
  handleAmountChanged,
  placeholder,
}: Props) {
  return (
    <Box display="flex" flexDirection="row" sx={{ flexGrow: "1" }} width="100%">
      <TextField
        type="number"
        label={placeholder}
        variant="outlined"
        fullWidth
        value={amount}
        onChange={handleAmountChanged}
      />
    </Box>
  );
}
