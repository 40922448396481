
import axios, { AxiosRequestConfig } from "axios";
import { createHmac } from "./createHmac";

const getAuthorizationValue = (data: any): string => {
    const hmac = createHmac(JSON.stringify(data));
    return hmac;
};

const SERVER = process.env.REACT_APP_SERVER_BASE_URL || "";

export const axiosPost = async (url: string, data: any, authorization: string | null | undefined) => {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                'X-HMAC': getAuthorizationValue(data),
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
        };
        const response = await axios.post(`${SERVER}/${url}`, data, config);
        return response?.data;
    } catch (error) {
        console.error("Error in axios POST request:", error);
        throw error;
    }
};

export const axiosGet = async (url: string, authorization: string | null | undefined) => {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                'X-HMAC': getAuthorizationValue({}),
                'Content-Type': 'application/json',
                'Authorization': authorization
            },
        };
        const response = await axios.get(`${SERVER}/${url}`, config);
        return response.data;
    } catch (error) {
        console.error("Error in axios GET request:", error);
        throw error;
    }
};
