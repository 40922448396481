import { Box } from "@mui/material";
import { NftAttributes } from "./nftAttributes";

export default function BunPosition(extraInfo: any) {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <img src={extraInfo.image} width={100} height={100} alt="s" />
      <NftAttributes attributes={extraInfo} />
    </Box>
  );
}
