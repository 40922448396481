import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the context type
interface LoaderContextType {
    loading: boolean;
    showLoader: () => void;
    hideLoader: () => void;
}

// Create the context with default values
const LoaderContext = createContext<LoaderContextType>({
    loading: false,
    showLoader: () => { },
    hideLoader: () => { },
});

// Provide context to components
export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    return (
        <LoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

// Custom hook to use the loader context
export const useLoader = (): LoaderContextType => useContext(LoaderContext);
