import BunPosition from "./bunPositions";
import GenericPosition from "./generic";
import LMBuyPosition from "./lmBuyPositions";

const addressToComp: any = {
  "0xa05D0ACeA82d84fB9667a3fa33b33dCa8b4D712B": LMBuyPosition,
  "0xE3351CE33689dc444B1a45B8f8F447A181D57227": BunPosition,
  //Test Buns - delete in prod
  "0x53c302b14382dE9A8D525110560c99bf32Ab0968": BunPosition,
  "0xE40A99f9E492737903Dc79dA163D6d45d0158B5d": BunPosition,
};

// {
//   address: "0xc447597EfC493E36cCCA16707559Fb7238D0367c",
//   name: "Smoove Mobile Sim Card",
//   symbol: "SMS",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },
// {
//   address: "0x6EFa25C069A14B9F516028c6b99f14F5D365Fb3b",
//   name: "Payasans",
//   symbol: "PAYASANS",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },
// {
//   address: "0x7807756A93Bc38eD2EBF06088696FcdFFb1C06a7",
//   name: "FarmTek Disc",
//   symbol: "FTD",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },
// {
//   address: "0x1F18970C26eFA3352ebDaA7970F3b839A8569394",
//   name: "Pixel Buns",
//   symbol: "PBUN",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },
// {
//   address: "0x18e1F1a5d14e369Ca165C36FD3dcA3D8F074Fe29",
//   name: "Sanko Pet Consumables",
//   symbol: "(SPC)",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },
// {
//   address: "0xd7e72EaC04e75425FCd05c056990B81b82933EBE",
//   name: "Sanko Pet Items",
//   symbol: "(SPI)",
//   decimals: 18,
//   chainId: ChainEnum.Sanko,
//   logoURI:
//     "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png",
//   isNft: true,
// },

interface Props {
  address: string;
  extraInfo: any;
}

export function RenderExtraInfo({ address, extraInfo }: Props) {
  return (
    addressToComp[address]?.(extraInfo) || (
      <GenericPosition extraInfo={extraInfo} />
    )
  );
}
