import { currentMarket } from "../../config/nft/lm";
import { getPriceFromTick } from "../../utilities/getPriceFromTick";
import { useMarketStore } from "../../zustand/useMarketStore";
import useTokenPricesStore from "../../zustand/useTokenPricesStore";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { AbiCoder, formatUnits } from "ethers";
import { NftAttributes } from "./nftAttributes";
import { useSimulateContract } from "wagmi";
import { dopexV2OptionMarket } from "../../config";

export const swapRouterSwappers = "0x60049938188107E625091A0F0d00f6a018beC2D1";
export default function LMBuyPosition(extraInfo: any) {
  const { slot0 } = useMarketStore();
  const { prices } = useTokenPricesStore();

  const markPrice = useMemo(() => {
    return getPriceFromTick(
      slot0.tick,
      10 ** currentMarket.token0.decimals,
      10 ** currentMarket.token1.decimals,
      currentMarket.inversePrice
    );
  }, [slot0.tick]);

  const txParamsData = useMemo(() => {
    const liquidityToExercise = extraInfo.liquidities || [];
    return {
      optionId: extraInfo.tokenId,
      liquidityToExercise: liquidityToExercise.map(
        (val: any) => BigInt(val) - BigInt(1)
      ),
      swapper: liquidityToExercise.map(() => swapRouterSwappers),
      swapData: liquidityToExercise.map(() =>
        new AbiCoder().encode(
          ["uint24", "uint256"],
          [currentMarket?.primePool.feeBps ?? 0, 0]
        )
      ),
    };
  }, [extraInfo]);

  const { data: profit } = useSimulateContract({
    abi: dopexV2OptionMarket.abi,
    address: currentMarket.address as any,
    functionName: "exerciseOption",
    args: [
      {
        optionId: txParamsData.optionId,
        swapper: txParamsData.swapper,
        swapData: txParamsData.swapData,
        liquidityToExercise: txParamsData.liquidityToExercise,
      },
    ],
    account: extraInfo.user as `0x${string}`,
  });

  const position = useMemo(() => {
    const { callToken, putToken, token0, token1, inversePrice } = currentMarket;

    const options = [extraInfo];

    for (const {
      isCall,
      tickLower,
      tickUpper,
      size,
      premium,
      expiry,
    } of options) {
      const tick = isCall ? tickUpper : tickLower;
      const decimals = isCall ? callToken.decimals : putToken.decimals;

      const putTokenPrice = prices[currentMarket?.putToken.symbol as string];
      const callTokenPrice = prices[currentMarket?.callToken.symbol as string];
      const strike = getPriceFromTick(
        tick,
        10 ** token0.decimals,
        10 ** token1.decimals,
        inversePrice
      );

      const strikeUsd = strike * putTokenPrice;

      const sizeReadable = Number(formatUnits(BigInt(size), decimals));

      const sizeUsdValue =
        (isCall ? sizeReadable * markPrice : sizeReadable) * putTokenPrice;
      //@ts-ignore
      const pnl = profit?.result?.totalProfit || BigInt(0);
      const pnlReadable = Number(formatUnits(pnl, decimals));
      const pnlUsdValue = isCall
        ? pnlReadable * putTokenPrice
        : pnlReadable * callTokenPrice;
      const premiumReadable = Number(formatUnits(BigInt(premium), decimals));
      const premiumInQuoteAsset = isCall
        ? premiumReadable * markPrice
        : premiumReadable;
      const premiumUsdValue = premiumInQuoteAsset * putTokenPrice;

      const optionsAmount = !isCall
        ? Number(sizeUsdValue) / (strike * putTokenPrice)
        : sizeReadable;

      const breakeven = !isCall
        ? Number(strike * putTokenPrice) - premiumUsdValue / optionsAmount
        : Number(strike * putTokenPrice) + premiumUsdValue / optionsAmount;

      let extra =
        Number(expiry) < Date.now() / 1000
          ? { Expired: "" }
          : {
              Breakeven: `$${breakeven.toFixed(4)}`,
              Size: `$${sizeUsdValue.toFixed(4)}`,
              PNL: `$${pnlUsdValue.toFixed(4)}`,
            };

      return {
        Market: `${currentMarket.callToken.symbol}/${currentMarket.putToken.symbol}`,
        Strike: `$${strikeUsd.toFixed(4)}`,
        ...extra,
      };
    }
  }, [extraInfo, prices, markPrice, profit]);

  return (
    <Box display="flex" flexDirection="column">
      <span>{`# ${extraInfo.tokenId}`}</span>
      <NftAttributes attributes={position} />
    </Box>
  );
}
